import { EVENT_TYPE, NOTIFICATION_DELIVERY_CHANNEL } from '@finance-ops/constants';
import { NotificationConfigMapType } from './NotificationType';

export const DEFAULT_NOTIFICATIONS_EXTERNAL: Partial<NotificationConfigMapType> = {
  [EVENT_TYPE.ACTION_CREATED]: {
    isEnabled: true,
    deliveryModes: {
      [NOTIFICATION_DELIVERY_CHANNEL.WEBAPP]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.EMAIL]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.PUSH]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.SMS]: false,
    },
  },
  [EVENT_TYPE.COMMENT_ADDED]: {
    isEnabled: true,
    deliveryModes: {
      [NOTIFICATION_DELIVERY_CHANNEL.WEBAPP]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.EMAIL]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.PUSH]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.SMS]: false,
    },
  },
  [EVENT_TYPE.ACTION_UPDATE]: {
    isEnabled: true,
    deliveryModes: {
      [NOTIFICATION_DELIVERY_CHANNEL.WEBAPP]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.EMAIL]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.PUSH]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.SMS]: false,
    },
  },
};

export const DEFAULT_NOTIFICATIONS_INTERNAL: Partial<NotificationConfigMapType> = {
  [EVENT_TYPE.MESSAGE_INBOUND]: {
    isEnabled: true,
    deliveryModes: {
      [NOTIFICATION_DELIVERY_CHANNEL.WEBAPP]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.EMAIL]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.PUSH]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.SMS]: false,
    },
  },
  [EVENT_TYPE.TASK_UPDATE_MODE]: {
    isEnabled: true,
    deliveryModes: {
      [NOTIFICATION_DELIVERY_CHANNEL.WEBAPP]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.EMAIL]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.PUSH]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.SMS]: false,
    },
  },
  [EVENT_TYPE.ACTION_UPDATE]: {
    isEnabled: true,
    deliveryModes: {
      [NOTIFICATION_DELIVERY_CHANNEL.WEBAPP]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.EMAIL]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.PUSH]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.SMS]: false,
    },
  },
  [EVENT_TYPE.ACTION_CREATED]: {
    isEnabled: true,
    deliveryModes: {
      [NOTIFICATION_DELIVERY_CHANNEL.WEBAPP]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.EMAIL]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.PUSH]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.SMS]: false,
    },
  },
  [EVENT_TYPE.COMMENT_ADDED]: {
    isEnabled: true,
    deliveryModes: {
      [NOTIFICATION_DELIVERY_CHANNEL.WEBAPP]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.EMAIL]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.PUSH]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.SMS]: false,
    },
  },
  [EVENT_TYPE.PAYMENT_SUCCESS]: {
    isEnabled: true,
    deliveryModes: {
      [NOTIFICATION_DELIVERY_CHANNEL.WEBAPP]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.EMAIL]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.PUSH]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.SMS]: false,
    },
  },
  [EVENT_TYPE.INVOICE_LINK_OPENED]: {
    isEnabled: true,
    deliveryModes: {
      [NOTIFICATION_DELIVERY_CHANNEL.WEBAPP]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.EMAIL]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.PUSH]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.SMS]: false,
    },
  },
  [EVENT_TYPE.PAYMENT_LINK_OPENED]: {
    isEnabled: true,
    deliveryModes: {
      [NOTIFICATION_DELIVERY_CHANNEL.WEBAPP]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.EMAIL]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.PUSH]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.SMS]: false,
    },
  },
  [EVENT_TYPE.NOTE_CREATED]: {
    isEnabled: true,
    deliveryModes: {
      [NOTIFICATION_DELIVERY_CHANNEL.WEBAPP]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.EMAIL]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.SMS]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.PUSH]: false,
    },
  },
  [EVENT_TYPE.EMAIL_RECEIVED]: {
    isEnabled: true,
    deliveryModes: {
      [NOTIFICATION_DELIVERY_CHANNEL.WEBAPP]: true,
      [NOTIFICATION_DELIVERY_CHANNEL.EMAIL]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.PUSH]: false,
      [NOTIFICATION_DELIVERY_CHANNEL.SMS]: false,
    },
  },
};
