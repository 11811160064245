const DisputeWithCircle = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='37' height='37' viewBox='0 0 37 37' fill='none'>
      <rect
        x='0.82959'
        y='0.500092'
        width='35.3481'
        height='35.3481'
        rx='17.674'
        stroke='#A3A3A3'
        strokeOpacity='0.38'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.2537 20.688C11.2685 22.634 12.8567 24.2002 14.8027 24.188H15.2837L16.7057 25.688C16.8499 25.8368 17.0484 25.9208 17.2557 25.9208C17.4629 25.9208 17.6614 25.8368 17.8057 25.688L19.2277 24.188H19.7087C21.6531 24.198 23.2388 22.6324 23.2537 20.688V16.428C23.2388 14.482 21.6506 12.9158 19.7047 12.928H14.8047C12.8579 12.9147 11.2685 14.4812 11.2537 16.428V20.688Z'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.0037 12.928C13.0037 13.3422 13.3394 13.678 13.7537 13.678C14.1679 13.678 14.5037 13.3422 14.5037 12.928H13.0037ZM22.2027 10.428V11.1781L22.2166 11.1779L22.2027 10.428ZM24.6869 11.4035L24.1665 11.9436L24.1665 11.9436L24.6869 11.4035ZM25.7537 13.85H26.5038L26.5035 13.8362L25.7537 13.85ZM23.2537 20.678C22.8394 20.678 22.5037 21.0138 22.5037 21.428C22.5037 21.8422 22.8394 22.178 23.2537 22.178V20.678ZM14.2537 16.678C13.8394 16.678 13.5037 17.0138 13.5037 17.428C13.5037 17.8422 13.8394 18.178 14.2537 18.178V16.678ZM20.0187 18.178C20.4329 18.178 20.7687 17.8422 20.7687 17.428C20.7687 17.0138 20.4329 16.678 20.0187 16.678V18.178ZM14.9747 18.786C14.5604 18.786 14.2247 19.1218 14.2247 19.536C14.2247 19.9502 14.5604 20.286 14.9747 20.286V18.786ZM19.2977 20.286C19.7119 20.286 20.0477 19.9502 20.0477 19.536C20.0477 19.1218 19.7119 18.786 19.2977 18.786V20.286ZM14.5037 12.928C14.5037 12.2388 14.7694 11.8603 15.1815 11.6109C15.6519 11.3262 16.377 11.178 17.3027 11.178V9.67801C16.2684 9.67801 15.219 9.83484 14.4048 10.3276C13.5324 10.8557 13.0037 11.7272 13.0037 12.928H14.5037ZM17.3027 11.178H22.2027V9.67801H17.3027V11.178ZM22.2166 11.1779C22.9424 11.1644 23.6438 11.4399 24.1665 11.9436L25.2073 10.8635C24.3982 10.0837 23.3123 9.65732 22.1888 9.67814L22.2166 11.1779ZM24.1665 11.9436C24.6892 12.4473 24.9904 13.1381 25.0038 13.8639L26.5035 13.8362C26.4828 12.7126 26.0165 11.6433 25.2073 10.8635L24.1665 11.9436ZM25.0037 13.85V18.006H26.5037V13.85H25.0037ZM25.0037 18.006C25.0037 18.9139 24.878 19.5983 24.6121 20.0373C24.389 20.4057 24.0237 20.678 23.2537 20.678V22.178C24.4836 22.178 25.3683 21.6843 25.8952 20.8143C26.3794 20.0147 26.5037 18.9882 26.5037 18.006H25.0037ZM14.2537 18.178H20.0187V16.678H14.2537V18.178ZM14.9747 20.286H19.2977V18.786H14.9747V20.286Z'
        fill='#3E3E3E'
      />
    </svg>
  );
};

export default DisputeWithCircle;
