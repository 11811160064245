import { baseQuery } from './baseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

export interface CsvProcessingResponse {
  jobId: string;
  totalRecords: number;
  message: string;
}

export interface CsvProgressResponse {
  jobId: string;
  clientId: string;
  customersCount: number;
  messagesSuccessfulCount: number;
  messagesFailedCount: number;
  messagesSkippedCount: number;
  failedRecordsCount: number;
  createdAt: string;
  updatedAt: string;
  completedAt?: string;
  isCompleted: boolean;
}

export interface S3FileInfo {
  key: string;
  size: number;
  lastModified: Date;
  filename: string;
  contentType: string;
}

export interface ClientCsvConfigResponse {
  hasConfig: boolean;
  hasHeaderMappings: boolean;
  csvConfig: Record<string, any>;
  headerMappings: Record<string, string>;
  fileInfo: S3FileInfo | null;
}

export const processCSVApi = createApi({
  reducerPath: 'processCSVApi',
  baseQuery: baseQuery,
  tagTypes: ['ProcessCSV'],
  endpoints: builder => ({
    processCSV: builder.mutation<CsvProcessingResponse, { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/csv-import/process-csv/s3`,
        method: 'POST',
        body: { clientId },
      }),
    }),
    getCSVProgress: builder.query<CsvProgressResponse, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/csv-import/progress/${jobId}`,
        method: 'GET',
      }),
    }),
    getFailedRecords: builder.query<any[], { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/csv-import/failed-records/${jobId}`,
        method: 'GET',
      }),
    }),
    getClientConfig: builder.query<ClientCsvConfigResponse, { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/csv-import/client-config/${clientId}`,
        method: 'GET',
      }),
    }),
    getClientProgress: builder.query<CsvProgressResponse | null, { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/csv-import/client-progress/${clientId}`,
        method: 'GET',
      }),
    }),
    getFileInfo: builder.query<S3FileInfo | null, { clientId: string }>({
      query: ({ clientId }) => ({
        url: `/csv-import/file-info/${clientId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useProcessCSVMutation,
  useGetCSVProgressQuery,
  useLazyGetCSVProgressQuery,
  useGetFailedRecordsQuery,
  useGetClientConfigQuery,
  useLazyGetClientConfigQuery,
  useGetClientProgressQuery,
  useLazyGetClientProgressQuery,
  useGetFileInfoQuery,
} = processCSVApi;
