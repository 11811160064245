import { ACTION_CENTER_STATUS_TYPES } from '@finance-ops/constants';
import { PERMISSION_ACTION } from './PermissionActionType';
import { PERMISSION_SUBJECT, PermissionSubjectType } from './PermissionSubjectType';

export const PERMISSION_CONFIG: PermissionSubjectType = {
  [PERMISSION_SUBJECT.ALL]: {
    displayText: 'All',
    description: 'Access to all the pages',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.ACTION]: {
    displayText: 'Action',
    description: 'User access to actions',
    displayMode: 'TOGGLE',
    isInternal: true,
    conditions: {
      [PERMISSION_ACTION.READ]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.CREATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.UPDATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.DELETE]: {
        clientId: 'user.customerId',
      },
    },
  },
  [PERMISSION_SUBJECT.CATS]: {
    displayText: 'Cat',
    description: 'User can view cat',
    displayMode: 'TOGGLE',
    isInternal: true,
    conditions: {
      [PERMISSION_ACTION.READ]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.CREATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.UPDATE]: {
        $or: [
          {
            clientId: 'user.customerId',
          },
          {
            createdBy: 'user.id',
          },
        ],
      },
      [PERMISSION_ACTION.DELETE]: {
        $or: [
          {
            clientId: 'user.customerId',
          },
          {
            createdBy: 'user.id',
          },
        ],
      },
    },
  },
  [PERMISSION_SUBJECT.CLIENT]: {
    displayText: 'Client',
    description: 'User can view client',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.CHAT_PAGE]: {
    displayText: 'Chat Page',
    description: 'User can view chat page',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.TEAM_MANAGEMENT]: {
    displayText: 'Team Management',
    description: 'User can view team management',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.USER_MANAGEMENT]: {
    displayText: 'User Management',
    description: 'User can view user management',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.ROLE_MANAGEMENT]: {
    displayText: 'Role Management',
    description: 'User can view role management',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.STRATEGY_PAGE]: {
    displayText: 'Strategy Page',
    description: 'User can view strategy page',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.WORKFLOW_PAGE]: {
    displayText: 'Workflow Page',
    description: 'User can view workflow page',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.INVOICES_PAGE]: {
    displayText: 'Invoices Page',
    description: 'User can view invoices page',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.CHAT_PAGE_QUICK_FILTER_ALL]: {
    displayText: 'Chat Page Quick Filters',
    description: 'User can view quick filter all tab on chat page',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.WHATSAPP_COMMUNICATION]: {
    displayText: 'Whatsapp Communication',
    description: 'User can view whatsapp communication',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.QUEUE_PAGE]: {
    displayText: 'Queue Page',
    description: 'User can view queue page',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.SETTINGS_PAGE]: {
    displayText: 'Settings Page',
    description: 'User can view settings page',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.NEW_DASHBOARD_PAGE]: {
    displayText: 'New Dashboard Page',
    description: 'User can view new dashboard page',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.NON_ACL_PAGE]: {
    displayText: 'Non ACL Page',
    description: 'User can view non ACL page',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.PLAYGROUND]: {
    displayText: 'Playground',
    description: 'User can view playground',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.AI_GENERATED_FEEDBACK]: {
    displayText: 'AI Generated Feedback',
    description: 'User can view AI generated feedback',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.ASSIGN_COPILOT_EXTERNAL]: {
    displayText: 'Assign Copilot External',
    description: 'User can assign copilot external',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.SELECT_CLIENT_ID]: {
    displayText: 'Select Client Dropdown',
    description: 'User can select client using dropdown menu',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.CLIENTS_ASSIGN]: {
    displayText: 'Assign Clients Internal',
    description: 'User can assign clients to internal users',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.CUSTOMERS_ASSIGN]: {
    displayText: 'Assign Customers',
    description: 'User can assign customers to other users and autopilot',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.COLLECTION_MANAGER_INTERNAL]: {
    displayText: 'Collection Manager Internal',
    description: 'Use can manage customers of clients assigned to them',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.COLLECTION_MANAGER_EXTERNAL]: {
    displayText: 'Collection Manager External',
    description: 'User can manage customers',
    displayMode: 'TOGGLE',
    isInternal: false,
    conditions: {
      clientId: 'user.customerId',
    },
  },
  [PERMISSION_SUBJECT.COLLECTION_AGENT_INTERNAL]: {
    displayText: 'Internal Collection Agent',
    description: 'User can handle assigned customers',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.COLLECTION_AGENT_EXTERNAL]: {
    displayText: 'External Collection Agent',
    description: 'User can handle assigned customers',
    displayMode: 'TOGGLE',
    isInternal: false,
    conditions: {
      clientId: 'user.customerId',
    },
  },
  [PERMISSION_SUBJECT.DB_VIEWER]: {
    displayText: 'DB Viewer',
    description: 'User can view DB Viewer',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.PAYMENTS_PAGE]: {
    displayText: 'Payments Page',
    description: 'User can view payments page',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.TASK_MODE]: {
    displayText: 'Task Mode',
    description: 'User can view task mode',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.TASK]: {
    displayText: 'Task',
    description: 'User can view task',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.ACTION_UPDATE]: {
    displayText: 'Action Update',
    description: 'User can update actions',
    displayMode: 'TOGGLE',
    isInternal: true,
    conditions: {
      // 'userEntity.assignedAgent.id': 'user.id',
      $or: [
        {
          clientId: 'user.customerId',
          // 'userEntity.status': { $nin: [ACTION_CENTER_STATUS_TYPES.CLOSED, ACTION_CENTER_STATUS_TYPES.RESOLVED] },
        },
        { 'userEntity.issuerId': 'user.id' },
      ],
    },
  },
  [PERMISSION_SUBJECT.ACTION_COMMENT_CREATE]: {
    displayText: 'Action Comments Create',
    description: 'User can comment on actions',
    displayMode: 'TOGGLE',
    isInternal: true,
    conditions: {
      $or: [
        {
          clientId: 'user.customerId',
          // 'userEntity.status': { $nin: [ACTION_CENTER_STATUS_TYPES.CLOSED, ACTION_CENTER_STATUS_TYPES.RESOLVED] },
        },
        { 'userEntity.issuerId': 'user.id', 'userEntity.status': { $ne: ACTION_CENTER_STATUS_TYPES.CLOSED } },
      ],
    },
  },
  [PERMISSION_SUBJECT.ACTION_COMMENT_UPDATE]: {
    displayText: 'Action Comments Update',
    description: 'User can update comments on actions',
    displayMode: 'TOGGLE',
    isInternal: true,
    conditions: {
      commentedById: 'user.id',
    },
  },
  [PERMISSION_SUBJECT.ONLY_SUPER_ADMIN]: {
    displayText: 'Only Super Admin',
    description: 'User can view only super admin',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.CUSTOMERS_PAGE_QUICK_FILTERS]: {
    displayText: 'Show Quick Filters For Customer Page',
    description: 'User can view quick filter show all on customer page',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.HIDE_MESSAGES]: {
    displayText: 'Hide Messages',
    description: 'User can hide messages',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.ONLY_INTERNAL_USER]: {
    displayText: 'Only Internal User',
    description: 'User can view only internal user',
    displayMode: 'TOGGLE',
    isInternal: true,
  },
  [PERMISSION_SUBJECT.MAKE_CALL]: {
    displayText: 'Make Call',
    description: 'User can make a call',
    displayMode: 'TOGGLE',
    isInternal: true,
  },

  [PERMISSION_SUBJECT.CUSTOMER_CONTEXTS]: {
    displayText: 'CustomerContexts',
    description: 'User can manage customer-contexts',
    displayMode: 'TOGGLE',
    isInternal: true,
    conditions: {
      [PERMISSION_ACTION.READ]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.CREATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.UPDATE]: {
        createdBy: 'user.id',
      },
      [PERMISSION_ACTION.DELETE]: {
        createdBy: 'user.id',
      },
    },
  },

  [PERMISSION_SUBJECT.CUSTOMERS]: {
    displayText: 'Customers',
    description: 'User can manage customers',
    displayMode: 'TOGGLE',
    isInternal: true,
    conditions: {
      [PERMISSION_ACTION.READ]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.CREATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.UPDATE]: {
        createdBy: 'user.id',
      },
      [PERMISSION_ACTION.DELETE]: {
        createdBy: 'user.id',
      },
    },
  },

  [PERMISSION_SUBJECT.INVOICES]: {
    displayText: 'Invoices',
    description: 'User can manage invoices',
    displayMode: 'TOGGLE',
    isInternal: true,
    conditions: {
      [PERMISSION_ACTION.READ]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.CREATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.UPDATE]: {
        createdBy: 'user.id',
      },
      [PERMISSION_ACTION.DELETE]: {
        createdBy: 'user.id',
      },
    },
  },

  [PERMISSION_SUBJECT.TASKS]: {
    displayText: 'Tasks',
    description: 'User can manage tasks',
    displayMode: 'TOGGLE',
    isInternal: true,
    conditions: {
      [PERMISSION_ACTION.READ]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.CREATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.UPDATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.DELETE]: {
        clientId: 'user.customerId',
      },
    },
  },

  [PERMISSION_SUBJECT.TRANSACTIONS]: {
    displayText: 'Transactions',
    description: 'User can manage transactions',
    displayMode: 'TOGGLE',
    isInternal: true,
    conditions: {
      [PERMISSION_ACTION.READ]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.CREATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.UPDATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.DELETE]: {
        clientId: 'user.customerId',
      },
    },
  },

  [PERMISSION_SUBJECT.GATEWAY_TRANSACTIONS]: {
    displayText: 'GatewayTransactions',
    description: 'User can manage gatewayTransactions',
    displayMode: 'TOGGLE',
    isInternal: true,
    conditions: {
      [PERMISSION_ACTION.READ]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.CREATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.UPDATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.DELETE]: {
        clientId: 'user.customerId',
      },
    },
  },

  [PERMISSION_SUBJECT.PAYMENT_PLANS]: {
    displayText: 'PaymentPlans',
    description: 'User can manage paymentPlans',
    displayMode: 'TOGGLE',
    isInternal: true,
    conditions: {
      [PERMISSION_ACTION.READ]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.CREATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.UPDATE]: {
        clientId: 'user.customerId',
      },
      [PERMISSION_ACTION.DELETE]: {
        clientId: 'user.customerId',
      },
    },
  },
};
