import {
  ActionType,
  CreateActionRequest,
  GetActionsResponse,
  UpdateActionMutationRequest,
} from '@finance-ops/collections/action-types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { stringify } from 'qs';
import { GetAll } from '@finance-ops/repository';

export const apiController = new AbortController();

const url = '/v3/actions';

export const actionsApiV2 = createApi({
  reducerPath: 'actionsApiV2',
  baseQuery,
  tagTypes: ['Actions'],
  endpoints: builder => ({
    getActions: builder.query<GetActionsResponse, GetAll<ActionType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}?${queryString}`,
        };
      },
      providesTags: ['Actions'],
    }),
    getActionsCount: builder.query<number, GetAll<ActionType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}/count?${queryString}`,
        };
      },
      providesTags: ['Actions'],
    }),
    getActionById: builder.query<ActionType, string>({
      query: id => `${url}/${id}`,
      providesTags: (_, __, id) => [{ type: 'Actions', id }],
    }),
    createAction: builder.mutation<ActionType, CreateActionRequest>({
      query: body => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Actions'],
    }),
    updateAction: builder.mutation<ActionType, UpdateActionMutationRequest>({
      query: ({ id, body }) => {
        return {
          url: `${url}/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Actions', id }, 'Actions'],
    }),
    deleteAction: builder.mutation<void, string>({
      query: id => ({
        url: `${url}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Actions'],
    }),
  }),
});

export const {
  useGetActionsQuery,
  useGetActionsCountQuery,
  useGetActionByIdQuery,
  useCreateActionMutation,
  useUpdateActionMutation,
  useDeleteActionMutation,
} = actionsApiV2;
