import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar'; // For Left Position
import CloseIcon from '@mui/icons-material/Close'; // Close Icon
import { fetchLogs, clearLogs } from '../../utils/pglite/db';
import RefreshIcon from '@mui/icons-material/Refresh'; // Add this import
import HttpMethodText from './HttpMethodText';
import WebsocketDirectionIcon from './WebsocketDirectionIcon';
import DateAgo from '../common/DateAgo';
import DeleteIcon from '@mui/icons-material/Delete';
import ResponseSize from './ResponseSize';
import StatusCode from './StatusCode';
import Duration from './Duration';
import type { Log } from '../../utils/pglite/types';
import FullscreenIcon from '@mui/icons-material/Fullscreen'; // Add this import
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'; // Add this import
import { safeLocalStorage } from 'apps/webapp/src/hooks/useLocalStorage';
import JsonBody from './JsonBody';
import { devtoolsEnabled } from '../../utils/pglite/config';
import { DB_NAME } from '../../utils/pglite/config';

if (!devtoolsEnabled && typeof window !== 'undefined') {
  const dbName = `/pglite/${DB_NAME}`;
  const DBDeleteRequest = window.indexedDB.deleteDatabase(dbName);

  DBDeleteRequest.onerror = event => {
    console.error('Error deleting database.');
  };

  DBDeleteRequest.onsuccess = (event: any) => {
    console.debug('Database deleted successfully');

    console.debug(event.result); // should be undefined
  };

  console.debug('Deleted database', dbName);
}

const DEVTOOL_POSITIONS = {
  LEFT: 'left-half',
  RIGHT: 'right-half',
  BOTTOM: 'bottom-half',
};

const MIN_WIDTH = 600;
const MIN_HEIGHT = 300;

const STORAGE_KEYS = {
  POSITION: 'devtool-position',
  SIZE: 'devtool-size',
  IS_OPEN: 'devtool-is-open',
} as const;

interface DevtoolSize {
  width: string;
  height: string;
}

function getStoredSize() {
  const storedSize = safeLocalStorage.getItem(STORAGE_KEYS.SIZE);
  if (storedSize) {
    try {
      return JSON.parse(storedSize);
    } catch (error) {
      console.error('[DevTools] Error parsing stored size:', error);
    }
  }
  return { width: '50%', height: '50%' };
}

function formatAbsoluteTime(timestamp: string) {
  const localDate = getLocalDate(timestamp);

  // Use Intl.DateTimeFormat for local time formatting
  const timeFormatter = new Intl.DateTimeFormat('default', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });

  const dateFormatter = new Intl.DateTimeFormat('default', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });

  const timeStr = timeFormatter.format(localDate);
  const dateStr = dateFormatter.format(localDate);

  return `${timeStr} ${dateStr}`;
}

function getLocalDate(timestamp: string) {
  const date = new Date(timestamp);
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return localDate;
}

export default function Devtool() {
  const [isDevtoolOpen, setDevtoolOpen] = useState(() => {
    return safeLocalStorage.getItem(STORAGE_KEYS.IS_OPEN) === 'true';
  });
  const [position, setPosition] = useState(() => {
    return safeLocalStorage.getItem('devtool-position') || DEVTOOL_POSITIONS.RIGHT;
  });
  const [logs, setLogs] = useState<Log[]>([]);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [selectedLog, setSelectedLog] = useState<Log | null>(null);
  const isSelectedHttp = selectedLog?.type === 'http';

  // Add auto-refresh functionality
  const [isAutoRefresh, setAutoRefresh] = useState(true);

  const [durationThreshold, setDurationThreshold] = useState(1000);
  const [sizeThreshold, setSizeThreshold] = useState(500);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [size, setSize] = useState(getStoredSize());
  const resizeRef = useRef<HTMLDivElement>(null);
  const isResizing = useRef(false);

  const [hoveredId, setHoveredId] = useState<number | null>(null);

  const scrollToBottom = useCallback(() => {
    if (tableContainerRef.current) {
      const container = tableContainerRef.current;
      // Force a reflow before scrolling
      container.scrollTop = container.scrollHeight;
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, []);

  // Memoize filtered logs
  const filteredLogs = useMemo(() => {
    return logs.slice(-1000); // Keep only last 1000 logs
  }, [logs]);

  // Memoize related logs lookup
  const relatedLogsMap = useMemo(() => {
    const map = new Map<string, number[]>();
    filteredLogs.forEach(log => {
      if (log.request_id) {
        const existing = map.get(log.request_id) || [];
        map.set(log.request_id, [...existing, log.id!]);
      }
    });
    return map;
  }, [filteredLogs]);

  // Optimize fetchLogsAsync
  const fetchLogsAsync = useCallback(async () => {
    try {
      const newLogs = await fetchLogs();
      if (newLogs.logs.length > 0) {
        // Only update if we have new logs
        const lastCurrentId = logs[logs.length - 1]?.id || 0;
        const hasNewLogs = newLogs.logs.some(log => log.id! > lastCurrentId);

        if (hasNewLogs) {
          setLogs(newLogs.logs);
          if (isAutoRefresh) {
            requestAnimationFrame(scrollToBottom);
          }
        }
      }
    } catch (error) {
      console.error('[DevTools] Error fetching logs:', error);
    }
  }, [isAutoRefresh, scrollToBottom, logs]);

  // Memoize table rows
  const tableRows = useMemo(() => {
    return filteredLogs.map((log, index) => {
      const isCurrentHttp = log.type === 'http';
      const relatedIds = log.request_id ? relatedLogsMap.get(log.request_id)?.filter(id => id !== log.id) : [];
      const isRelatedToHovered = hoveredId && (log.id === hoveredId || relatedIds?.includes(hoveredId));

      return (
        <TableRow
          key={log.id}
          onClick={() => setSelectedLog(log)}
          sx={{
            backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#ffffff',
            cursor: 'pointer',
            borderBottom: '1px solid #f0f0f0',
            '&:hover': {
              backgroundColor: 'primary.lighter',
            },
            ...(selectedLog?.id === log.id && {
              backgroundColor: 'primary.light',
            }),
          }}
        >
          <TableCell
            sx={{
              width: '60px',
              borderRight: '1px solid #e0e0e0',
              textAlign: 'center',
              backgroundColor: isRelatedToHovered ? 'primary.lighter' : 'inherit',
              transition: 'background-color 0.2s',
              '&:hover': {
                backgroundColor: 'primary.light',
                borderRadius: 1,
              },
            }}
            onMouseEnter={() => setHoveredId(log.id!)}
            onMouseLeave={() => setHoveredId(null)}
          >
            {log.id}
          </TableCell>
          <TableCell
            sx={{
              padding: '4px 8px',
              fontSize: '12px',
              borderRight: '1px solid #e0e0e0',
              fontFamily: '"Roboto Mono", monospace',
              textAlign: 'center',
              backgroundColor: isRelatedToHovered ? 'primary.lighter' : 'inherit',
              transition: 'background-color 0.2s',
              ...(relatedIds?.length && {
                '&:hover': {
                  backgroundColor: 'primary.light',
                  borderRadius: 1,
                },
              }),
            }}
          >
            {relatedIds?.map(id => (
              <Box
                key={id}
                onClick={e => {
                  e.stopPropagation();
                  const related = filteredLogs.find(l => l.id === id);
                  if (related) setSelectedLog(related);
                }}
                onMouseEnter={() => setHoveredId(id)}
                onMouseLeave={() => setHoveredId(null)}
                sx={{
                  cursor: 'pointer',
                  padding: '2px 6px',
                  borderRadius: '4px',
                }}
              >
                {id}
              </Box>
            ))}
          </TableCell>
          <TableCell
            sx={{
              borderRight: '1px solid #e0e0e0',
              padding: '4px 8px',
              fontSize: '12px',
              fontFamily: '"Roboto Mono", monospace',
              whiteSpace: 'nowrap',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {log.type === 'websocket' ? (
              <WebsocketDirectionIcon direction={log.direction || 'received'} />
            ) : (
              <HttpMethodText method={log.method || ''} />
            )}
          </TableCell>
          <TableCell
            sx={{
              padding: '4px 8px',
              fontSize: '12px',
              fontFamily: '"Roboto Mono", monospace',
              color: '#303942',
              borderRight: '1px solid #e0e0e0',
            }}
          >
            {isCurrentHttp ? log.url : log.method}
          </TableCell>
          <TableCell
            sx={{
              padding: '4px 8px',
              fontSize: '12px',
              fontFamily: '"Roboto Mono", monospace',
              borderRight: '1px solid #e0e0e0',
            }}
          >
            <StatusCode status={log.status || 0} />
          </TableCell>
          <TableCell
            sx={{
              padding: '4px 8px',
              fontSize: '12px',
              fontFamily: '"Roboto Mono", monospace',
              color: '#303942',
              borderRight: '1px solid #e0e0e0',
            }}
          >
            <ResponseSize
              response={log.response_body || log.websocket_payload || ''}
              threshold={sizeThreshold * 1024}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: '4px 8px',
              fontSize: '12px',
              fontFamily: '"Roboto Mono", monospace',
            }}
          >
            <Duration duration={log.duration || 0} threshold={durationThreshold} />
          </TableCell>
        </TableRow>
      );
    });
  }, [filteredLogs, relatedLogsMap, selectedLog?.id, setSelectedLog, hoveredId]);

  // Optimize auto-refresh
  useEffect(() => {
    if (!isDevtoolOpen || !isAutoRefresh) return;

    const intervalId = setInterval(fetchLogsAsync, 1000);
    return () => clearInterval(intervalId);
  }, [isDevtoolOpen, isAutoRefresh, fetchLogsAsync]);

  const handleClearLogs = async () => {
    await clearLogs();
    setLogs([]);
  };

  // Toggle Devtool with Keyboard Shortcut
  useEffect(() => {
    const toggleDevtool = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.code === 'KeyF') {
        setDevtoolOpen(prev => !prev);
      }
    };

    window.addEventListener('keydown', toggleDevtool);
    return () => {
      window.removeEventListener('keydown', toggleDevtool);
    };
  }, []);

  // Persist Position to Local Storage
  useEffect(() => {
    safeLocalStorage.setItem(STORAGE_KEYS.POSITION, position);
  }, [position]);

  // Add resize handler
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isResizing.current || !resizeRef.current) return;

      const container = resizeRef.current;

      if (position === DEVTOOL_POSITIONS.RIGHT) {
        const newWidth = window.innerWidth - e.clientX;
        if (newWidth >= MIN_WIDTH) {
          setSize((prev: DevtoolSize) => {
            const newSize = { ...prev, width: `${newWidth}px` };
            safeLocalStorage.setItem(STORAGE_KEYS.SIZE, JSON.stringify(newSize));
            return newSize;
          });
        }
      } else if (position === DEVTOOL_POSITIONS.LEFT) {
        const newWidth = e.clientX;
        if (newWidth >= MIN_WIDTH) {
          setSize((prev: DevtoolSize) => {
            const newSize = { ...prev, width: `${newWidth}px` };
            safeLocalStorage.setItem(STORAGE_KEYS.SIZE, JSON.stringify(newSize));
            return newSize;
          });
        }
      } else if (position === DEVTOOL_POSITIONS.BOTTOM) {
        const newHeight = window.innerHeight - e.clientY;
        if (newHeight >= MIN_HEIGHT) {
          setSize((prev: DevtoolSize) => {
            const newSize = { ...prev, height: `${newHeight}px` };
            safeLocalStorage.setItem(STORAGE_KEYS.SIZE, JSON.stringify(newSize));
            return newSize;
          });
        }
      }
    };

    const handleMouseUp = () => {
      isResizing.current = false;
      document.body.style.cursor = 'default';
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [position]);

  useEffect(() => {
    safeLocalStorage.setItem(STORAGE_KEYS.SIZE, JSON.stringify(size));
  }, [size]);

  // Add effect to persist open state
  useEffect(() => {
    safeLocalStorage.setItem(STORAGE_KEYS.IS_OPEN, isDevtoolOpen.toString());
  }, [isDevtoolOpen]);

  if (!isDevtoolOpen) return null;

  // Common table cell styles
  const tableCellStyles = {
    backgroundColor: '#f5f5f5',
    borderBottom: '1px solid #e0e0e0',
    borderRight: '1px solid #e0e0e0', // Add vertical border
    padding: '4px 8px',
    fontSize: '12px',
    fontWeight: 500,
    color: '#5f6368',
  };

  return (
    <Box
      ref={resizeRef}
      sx={{
        position: 'fixed',
        zIndex: 10000,
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: '"Roboto Mono", monospace',
        ...(isFullscreen && {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
        }),
        ...(!isFullscreen && {
          ...(position === DEVTOOL_POSITIONS.LEFT && {
            left: 0,
            top: 0,
            bottom: 0,
            width: size.width,
            '&:hover': {
              '&::after': {
                content: '""',
                position: 'absolute',
                right: -2,
                top: 0,
                width: 4,
                height: '100%',
                cursor: 'ew-resize',
              },
            },
          }),
          ...(position === DEVTOOL_POSITIONS.RIGHT && {
            right: 0,
            top: 0,
            bottom: 0,
            width: size.width,
            '&:hover': {
              '&::after': {
                content: '""',
                position: 'absolute',
                left: -2,
                top: 0,
                width: 4,
                height: '100%',
                cursor: 'ew-resize',
              },
            },
          }),
          ...(position === DEVTOOL_POSITIONS.BOTTOM && {
            left: 0,
            right: 0,
            bottom: 0,
            height: size.height,
            '&:hover': {
              '&::after': {
                content: '""',
                position: 'absolute',
                left: 0,
                top: -2,
                width: '100%',
                height: 4,
                cursor: 'ns-resize',
              },
            },
          }),
        }),
      }}
      onMouseDown={e => {
        // Only start resize if clicking near the edges
        const { clientX, clientY } = e;
        const rect = resizeRef.current?.getBoundingClientRect();
        if (!rect) return;

        const edgeThreshold = 10;
        const isNearLeftEdge = Math.abs(clientX - rect.left) < edgeThreshold;
        const isNearRightEdge = Math.abs(clientX - rect.right) < edgeThreshold;
        const isNearTopEdge = Math.abs(clientY - rect.top) < edgeThreshold;

        if (isNearLeftEdge || isNearRightEdge || isNearTopEdge) {
          isResizing.current = true;
          document.body.style.cursor = position === DEVTOOL_POSITIONS.BOTTOM ? 'ns-resize' : 'ew-resize';
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...(position === DEVTOOL_POSITIONS.BOTTOM && { borderRight: '1px solid #ccc' }),
          padding: '4px 8px',
          borderBottom: '1px solid #e0e0e0',
          backgroundColor: '#f5f5f5',
          minHeight: '32px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton title='Right Half' onClick={() => setPosition(DEVTOOL_POSITIONS.RIGHT)}>
            <ViewSidebarIcon />
          </IconButton>
          <IconButton title='Bottom Half' onClick={() => setPosition(DEVTOOL_POSITIONS.BOTTOM)}>
            <ViewSidebarIcon sx={{ transform: 'rotate(90deg)' }} />
          </IconButton>
          <IconButton title='Refresh' onClick={fetchLogsAsync} sx={{ mr: 1 }}>
            <RefreshIcon />
          </IconButton>
          <IconButton size='small' onClick={handleClearLogs}>
            <DeleteIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant='outlined'
            size='small'
            onClick={() => setAutoRefresh(!isAutoRefresh)}
            sx={{
              mr: 2,
              backgroundColor: isAutoRefresh ? 'inherit' : 'primary.main',
              color: isAutoRefresh ? 'primary.main' : 'white',
              '&:hover': {
                backgroundColor: isAutoRefresh ? 'inherit' : 'primary.dark',
              },
            }}
          >
            Auto Refresh {isAutoRefresh ? 'ON' : 'OFF'}
          </Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            size='small'
            type='number'
            value={durationThreshold}
            onChange={e => setDurationThreshold(Number(e.target.value))}
            label='Duration (ms)'
            sx={{
              width: '120px',
              ml: 1,
              '& .MuiInputBase-input': {
                py: 0.5,
                fontSize: '0.875rem',
              },
            }}
          />
          <TextField
            size='small'
            type='number'
            value={sizeThreshold}
            onChange={e => setSizeThreshold(Number(e.target.value))}
            label='Size (KB)'
            sx={{
              width: '120px',
              ml: 1,
              '& .MuiInputBase-input': {
                py: 0.5,
                fontSize: '0.875rem',
              },
            }}
          />
          <IconButton
            title={isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
            onClick={() => setIsFullscreen(!isFullscreen)}
            sx={{ mr: 1 }}
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
          <IconButton title='Close' onClick={() => setDevtoolOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          flex: selectedLog ? '50%' : '100%',
          overflow: 'auto',
          borderBottom: selectedLog ? '1px solid #e0e0e0' : 'none',
          maxHeight: 'calc(100vh - 48px)',
        }}
      >
        <TableContainer sx={{ maxHeight: '100%' }} ref={tableContainerRef}>
          <Table size='medium' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    ...tableCellStyles,
                    width: '50px',
                    textAlign: 'center',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    ...tableCellStyles,
                    width: '50px',
                    textAlign: 'center',
                  }}
                >
                  Related
                </TableCell>
                <TableCell
                  sx={{
                    ...tableCellStyles,
                    width: '50px',
                    textAlign: 'center',
                  }}
                >
                  Type
                </TableCell>
                <TableCell sx={{ ...tableCellStyles }}>Request</TableCell>
                <TableCell sx={{ ...tableCellStyles, width: '80px' }}>Status</TableCell>
                <TableCell sx={{ ...tableCellStyles, width: '80px' }}>Size</TableCell>
                <TableCell sx={{ ...tableCellStyles, width: '100px' }}>Duration</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </TableContainer>
      </Box>

      {selectedLog && (
        <Box
          sx={{
            flex: '50%',
            overflow: 'auto',
            padding: 2,
            backgroundColor: '#ffffff',
            borderTop: '1px solid #e0e0e0',
            position: 'relative',
          }}
        >
          <IconButton
            size='small'
            onClick={() => setSelectedLog(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box sx={{ mt: 1, mb: 3 }}>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontSize: '1.2rem', mb: 1 }}>
                {isSelectedHttp ? `HTTP Request Details (${selectedLog.id})` : `WebSocket Message (${selectedLog.id})`}
              </Typography>

              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 1 }}>
                <Typography
                  sx={{
                    backgroundColor: 'grey.100',
                    padding: '4px 8px',
                    borderRadius: 1,
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <strong>Request ID:</strong>&nbsp;{selectedLog.request_id}
                </Typography>
                <Typography
                  sx={{
                    backgroundColor: 'grey.100',
                    padding: '4px 8px',
                    borderRadius: 1,
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <strong>Method:</strong>&nbsp;{selectedLog.method}
                </Typography>

                {isSelectedHttp && (
                  <Typography
                    sx={{
                      backgroundColor: 'grey.100',
                      padding: '4px 8px',
                      borderRadius: 1,
                      flex: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <strong>URL:</strong>&nbsp;{selectedLog.full_url}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography
                  sx={{
                    backgroundColor: 'grey.100',
                    padding: '4px 8px',
                    borderRadius: 1,
                  }}
                >
                  <strong>Timestamp:</strong>&nbsp;{formatAbsoluteTime(selectedLog.timestamp)}
                </Typography>
                <Typography
                  component='span'
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                  }}
                >
                  (
                  <DateAgo
                    date={getLocalDate(selectedLog.timestamp)}
                    timeFormat={undefined}
                    sx={{
                      fontSize: 'inherit',
                      color: 'inherit',
                    }}
                  />
                  )
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              {selectedLog.status && (
                <Typography
                  sx={{
                    backgroundColor: 'grey.100',
                    padding: '4px 8px',
                    borderRadius: 1,
                  }}
                >
                  <strong>Status:</strong>&nbsp;{selectedLog.status}
                </Typography>
              )}
              {selectedLog.duration && (
                <Typography
                  sx={{
                    backgroundColor: 'grey.100',
                    padding: '4px 8px',
                    borderRadius: 1,
                  }}
                >
                  <strong>Duration:</strong>&nbsp;{selectedLog.duration} ms
                </Typography>
              )}
            </Box>
          </Box>

          {selectedLog.query_params && (
            <Box mt={2}>
              <Typography sx={{ fontSize: '1.2rem', mb: 1 }}>Query Parameters:</Typography>
              <Box
                component='pre'
                sx={{
                  bgcolor: '#f5f5f5',
                  p: 2,
                  borderRadius: 1,
                  maxHeight: '200px',
                  overflow: 'auto',
                }}
              >
                {(() => {
                  try {
                    const params = JSON.parse(selectedLog.query_params);
                    return JSON.stringify(params, null, 2);
                  } catch {
                    return selectedLog.query_params;
                  }
                })()}
              </Box>
            </Box>
          )}

          {selectedLog.request_body && (
            <Box mt={2}>
              <Typography sx={{ fontSize: '1.2rem', mb: 1 }}>Request Body</Typography>
              <JsonBody log={selectedLog} />
            </Box>
          )}

          <Box mt={2}>
            <Typography sx={{ fontSize: '1.2rem', mb: 1 }}>{isSelectedHttp ? 'Response Body' : 'Payload'}</Typography>
            {(selectedLog.response_body || selectedLog.websocket_payload) && <JsonBody log={selectedLog} />}
          </Box>
        </Box>
      )}
    </Box>
  );
}
