export const api = {
  aiCheckSend: 'api/ai/v2/check-send/predictions',
  reports: '/reports/generate-report',
  getCounterParty: '/user/counter_party/findById',
  customer: '/user/counter_party',
  getClientList: 'user/clientList',
  getChats: '/messages',
  getDashboardAggregatedData: `/aggregations/dashboard`,
  getFilteredInvoices: '/filter/invoice',
  getInvoices: '/invoice',
  postOpenDental: '/postOpenDental',
  info: '/info',
  sendOTP: '/sendOTP',
  invoiceList: '/invoiceList',
  verifyOTP: '/auth/verifyOTP',
  chats: '/chats/',
  paymentUrl: '/paymentUrl/',
  paymentUrlDeprecated: '/paymentUrlDeprecated/',
  generatePaymentLink: '/payment-link',
  paymentLink: '/payment-link',
  stripeCustomerDetails: '/payment/stripeCustomerDetails/',
  getPaymentMethods: '/payment/getPaymentMethods/',
  createPayment: '/createPayment/',
  sync: '/sync',
  pythonWs: '/pythonWs',
  changePassword: '/auth/changePassword',
  addNewTransactionInInvoice: '/invoice-api/addNewTransactionInInvoice',
  notifications: '/notifications',
  notificationPrevious: '/notifications/previous',
  getPresignedUrl: '/files/url/signed',
  getFiles: '/files',
  getOnboardingData: '/onboarding/data',
  postOnboardingData: '/onboarding/data',
  getOnboardingDataByReferralCode: '/onboarding/validate-referral-link',
  saveOnboardingDataForReferralCode: '/onboarding/save-onboarding-data-for-referral-code',
  uploadFile: '/files/upload',
  uploadIngestionDataError: '/fileUploadError',
  uploadOnboardingDataFile: '/onboarding/upload-data-file',
  sendAfterOnboardingAccountLoginLink: '/onboarding/send-email-link',
  resetPasswordForUsersWithOnboardingLink: '/onboarding/resetPasswordForUsersWithOnboardingLink',
  resetPasswordWithEmailToken: '/auth/resetPasswordWithEmailToken',
  setPasswordForNewUser: '/auth/setPasswordForNewUser',
  forgotPassword: '/auth/forgotPassword',
  transactions: '/transactions',
  transactionsPageExport: '/transactions/transactionsPage/export',
  manualTransaction: '/transactions/manualTransaction',
  action: '/actions',
  filterCustomerContext: '/customerContext/filterCustomerContext',
  customMetricsByClient: '/custom-metrics/clients',
  customMetrics: '/custom-metrics',
  getDashboardGraphs: '/getDashboardGraphs',
  aggregator: '/aggregator',
  openOrder: '/nuvei/open-order',
  webPaymentSuccess: '/nuvei/web-payment-success',
  clients: '/clients',
  users: '/users',
  customerContext: '/customerContext',
  customerContextCustomerPage: '/customerContext/customerPage',
  tasks: '/v2/tasks',
  targets: '/targets',
  faqs: '/faqs',
  payout: '/payout',
  getAvailToPayoutSummary: '/payout/getAvailToPayoutSummary',
  getReceipt: '/transactions/receipt',
};

export const PYTHON_API_PATHS = {
  ONBOARDING_INGESTION: `${api.pythonWs}/api/core/v1/data-integration/onboard-ingestion/demo`,
  WORKLIST_GENERATION: `${api.pythonWs}/api/ai/v1/worklist/predictions/trigger`,
  TRIGGER_WORKFLOW: `${api.pythonWs}/api/core/v1/rule-engine/workflow-strategies/trigger`,
  ASSIGN_STRATEGIES: `${api.pythonWs}/api/core/v1/rule-engine/correspondence-rules/assign`,
  CUSTOM_DATA_INGESTION: `${api.pythonWs}/api/core/v1/data-integration/onboard-ingestion/custom`,
  GET_BULK_SMS_STATS: `${api.pythonWs}/api/core/v1/auto-pilot/followup/bulk/stats`,
  POST_BULK_SMS: `${api.pythonWs}/api/core/v1/auto-pilot/followup/bulk/send`,
};

export const USERS_API_PATHS = {
  GET_USERS: '/users',
  UPDATE_USER: '/users',
  SETTINGS_USER: '/users/settings',
  NOTIFICATIONS_USER: '/users/notifications',
};

export const ROLE_API_PATHS = {
  GET_ROLES: '/roles',
  UPDATE_ROLE: '/roles',
  DELETE_ROLE: '/roles',
};

export const CLIENTS_API_PATHS = {
  CREATE_CLIENT: '/clients',
};

export const ONBOARDING_API_PATHS = {
  GET_SELF_ONBOARDING_LINK: 'onboarding/get-self-onboarding-link',
};

export default api;
