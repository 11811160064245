const TicketWithCircle = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='37' height='37' viewBox='0 0 37 37' fill='none'>
      <rect x='0.82959' y='0.5' width='35.3481' height='35.3481' rx='17.674' stroke='#A3A3A3' strokeOpacity='0.38' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7787 24.108L12.8507 25.052C12.7769 25.0888 12.6894 25.0851 12.6189 25.0423C12.5485 24.9995 12.505 24.9235 12.5037 24.841V11.507C12.5053 11.4248 12.549 11.3491 12.6194 11.3065C12.6897 11.2639 12.777 11.2604 12.8507 11.297L14.7787 12.241C14.8557 12.2786 14.9469 12.2729 15.0187 12.226L16.5707 11.213C16.6515 11.1605 16.7558 11.1605 16.8367 11.213L18.3707 12.213C18.4515 12.2656 18.5558 12.2656 18.6367 12.213L20.1707 11.213C20.2515 11.1605 20.3558 11.1605 20.4367 11.213L21.9877 12.226C22.0595 12.2729 22.1506 12.2786 22.2277 12.241L24.1567 11.297C24.2303 11.2604 24.3176 11.2639 24.388 11.3065C24.4584 11.3491 24.502 11.4248 24.5037 11.507V24.841C24.502 24.9233 24.4584 24.999 24.388 25.0416C24.3176 25.0842 24.2303 25.0877 24.1567 25.051L22.2287 24.107C22.1516 24.0695 22.0605 24.0752 21.9887 24.122L20.4367 25.135C20.3558 25.1876 20.2515 25.1876 20.1707 25.135L18.6367 24.135C18.5558 24.0825 18.4515 24.0825 18.3707 24.135L16.8367 25.135C16.7558 25.1876 16.6515 25.1876 16.5707 25.135L15.0197 24.122C14.9474 24.0752 14.8558 24.0699 14.7787 24.108Z'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5037 16.4241C15.0894 16.4241 14.7537 16.7599 14.7537 17.1741C14.7537 17.5883 15.0894 17.9241 15.5037 17.9241V16.4241ZM21.5037 17.9241C21.9179 17.9241 22.2537 17.5883 22.2537 17.1741C22.2537 16.7599 21.9179 16.4241 21.5037 16.4241V17.9241ZM16.5037 18.4241C16.0894 18.4241 15.7537 18.7599 15.7537 19.1741C15.7537 19.5883 16.0894 19.9241 16.5037 19.9241V18.4241ZM20.5037 19.9241C20.9179 19.9241 21.2537 19.5883 21.2537 19.1741C21.2537 18.7599 20.9179 18.4241 20.5037 18.4241V19.9241ZM15.5037 17.9241H21.5037V16.4241H15.5037V17.9241ZM16.5037 19.9241H20.5037V18.4241H16.5037V19.9241Z'
        fill='#3E3E3E'
      />
    </svg>
  );
};

export default TicketWithCircle;
