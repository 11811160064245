const DisputeIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='18' viewBox='0 0 17 18' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 11.5995C1.01486 13.5455 2.60302 15.1117 4.549 15.0995H5.03L6.452 16.5995C6.59628 16.7484 6.79471 16.8324 7.002 16.8324C7.20929 16.8324 7.40772 16.7484 7.552 16.5995L8.974 15.0995H9.455C11.3994 15.1095 12.9851 13.5439 13 11.5995V7.33952C12.9851 5.39355 11.397 3.82732 9.451 3.83952H4.551C2.60424 3.82621 1.01486 5.39276 1 7.33952L1 11.5995Z'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.75 3.83954C2.75 4.25376 3.08579 4.58954 3.5 4.58954C3.91421 4.58954 4.25 4.25376 4.25 3.83954H2.75ZM11.949 1.33954V2.08967L11.9629 2.08941L11.949 1.33954ZM14.4332 2.31508L13.9128 2.85513L13.9128 2.85513L14.4332 2.31508ZM15.5 4.76154H16.2501L16.2499 4.74769L15.5 4.76154ZM13 11.5895C12.5858 11.5895 12.25 11.9253 12.25 12.3395C12.25 12.7538 12.5858 13.0895 13 13.0895V11.5895ZM4 7.58954C3.58579 7.58954 3.25 7.92533 3.25 8.33954C3.25 8.75376 3.58579 9.08954 4 9.08954V7.58954ZM9.765 9.08954C10.1792 9.08954 10.515 8.75376 10.515 8.33954C10.515 7.92533 10.1792 7.58954 9.765 7.58954V9.08954ZM4.721 9.69754C4.30679 9.69754 3.971 10.0333 3.971 10.4475C3.971 10.8618 4.30679 11.1975 4.721 11.1975V9.69754ZM9.044 11.1975C9.45821 11.1975 9.794 10.8618 9.794 10.4475C9.794 10.0333 9.45821 9.69754 9.044 9.69754V11.1975ZM4.25 3.83954C4.25 3.15031 4.51573 2.77188 4.92787 2.52241C5.3982 2.23772 6.12331 2.08954 7.049 2.08954V0.589543C6.01469 0.589543 4.9653 0.746367 4.15113 1.23918C3.27877 1.76721 2.75 2.63877 2.75 3.83954H4.25ZM7.049 2.08954H11.949V0.589543H7.049V2.08954ZM11.9629 2.08941C12.6887 2.07597 13.3901 2.3514 13.9128 2.85513L14.9537 1.77503C14.1445 0.995242 13.0587 0.568854 11.9351 0.589671L11.9629 2.08941ZM13.9128 2.85513C14.4355 3.35885 14.7367 4.04959 14.7501 4.77539L16.2499 4.74769C16.2291 3.62412 15.7629 2.55481 14.9537 1.77503L13.9128 2.85513ZM14.75 4.76154V8.91754H16.25V4.76154H14.75ZM14.75 8.91754C14.75 9.82538 14.6243 10.5098 14.3585 10.9488C14.1354 11.3172 13.7701 11.5895 13 11.5895V13.0895C14.2299 13.0895 15.1146 12.5959 15.6415 11.7258C16.1257 10.9263 16.25 9.8997 16.25 8.91754H14.75ZM4 9.08954H9.765V7.58954H4V9.08954ZM4.721 11.1975H9.044V9.69754H4.721V11.1975Z'
        fill='#3E3E3E'
      />
    </svg>
  );
};

export default DisputeIcon;
