import { useSelector } from 'react-redux';
import { useAuth } from './useAuth';
import { RootState } from '../store';

const useSelectedClientId = () => {
  const { user } = useAuth();
  const client = useSelector((state: RootState) => state.client);
  const selectedId = client.selectedClientId;
  const selectedClientId = user?.customerId || selectedId;

  return selectedClientId;
};

export default useSelectedClientId;
