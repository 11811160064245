import { CustomerContextType, LoanStatus, TaskType } from '@finance-ops/types';
import { CustomerType, getFullName } from '../customer';
import { INVOICE_STATUS, InvoiceType } from '../invoice';

export class CustomerContext implements CustomerContextType {
  _id?: string;
  task: TaskType;
  customers: CustomerType[];
  invoices: InvoiceType[];
  totalDueAmount: number;
  names: string[];
  requestHash: string;
  searchScore: number;

  constructor(task: TaskType, customers: CustomerType[], invoices: InvoiceType[]) {
    this.task = task;
    const primaryCustomer = customers?.find(customer => customer.isPrimary);
    if (primaryCustomer) {
      this.customers = [primaryCustomer, ...customers.filter(customer => !customer.isPrimary)];
    } else {
      this.customers = customers;
    }
    const invoice = CustomerContext.getActiveInvoice(invoices);
    if (invoice) {
      this.invoices = [invoice, ...invoices.filter(inv => inv._id.toString() !== invoice._id.toString())];
    } else {
      this.invoices = invoices;
    }
    this.totalDueAmount = this.invoices?.reduce((acc, inv) => {
      if (inv.status !== INVOICE_STATUS.PAID) return acc + inv.dueAmount;
      else return acc;
    }, 0);
    this.names = this.customers.map(customer => getFullName(customer));
    this.searchScore = 0;
  }

  static create(customerContext: CustomerContextType) {
    const cc = new CustomerContext(customerContext.task, customerContext.customers, customerContext.invoices);
    if (customerContext.totalDueAmount !== undefined && customerContext.totalDueAmount !== null) {
      cc.totalDueAmount = customerContext.totalDueAmount;
    }
    if (customerContext.names?.length) {
      cc.names = customerContext.names;
    }
    if (customerContext.requestHash) {
      cc.requestHash = customerContext.requestHash;
    }
    if (customerContext.searchScore) {
      cc.searchScore = customerContext.searchScore;
    }
    return cc;
  }

  static revive({
    task,
    customers,
    invoices,
  }: {
    task: TaskType;
    customers: CustomerType[];
    invoices: InvoiceType[];
  }): CustomerContext {
    return new CustomerContext(task, customers, invoices);
  }

  static arraysToCustomerContext(
    taskArray: TaskType[],
    customerArray: CustomerType[],
    invoiceArray: InvoiceType[],
  ): CustomerContext[] {
    const customerContextArray: CustomerContext[] = [];
    for (const task of taskArray) {
      const customers = customerArray.filter(
        customer => customer._id.toString() === task.customerId || customer.parentId?.toString() === task.customerId,
      );
      const secondaryCustomerIds = customers
        .filter(customer => !customer.isPrimary)
        .map(customer => customer._id.toString());
      let invoices = invoiceArray.filter(invoice => invoice.counterPartyId === task.customerId);
      invoices = invoices.concat(invoiceArray.filter(invoice => secondaryCustomerIds.includes(invoice.counterPartyId)));
      const customerContext = new CustomerContext(task, customers, invoices);
      customerContextArray.push(customerContext);
    }
    return customerContextArray;
  }

  getPrimaryCustomer() {
    return this.customers.find(customer => customer.isPrimary);
  }

  getPrimaryCommunicationCustomer(primaryInternalCommunicationId?: string) {
    if (!primaryInternalCommunicationId) {
      return this.getPrimaryCustomer();
    }

    return this.customers.find(customer => customer._id.toString() === primaryInternalCommunicationId);
  }

  getCustomers() {
    return this.customers;
  }

  private static getActiveInvoice(invoices: InvoiceType[]) {
    let primaryInvoice = invoices?.find(inv => inv.status !== INVOICE_STATUS.PAID);
    if (primaryInvoice) {
      return primaryInvoice;
    }
    primaryInvoice = invoices?.find(inv => inv.metadata?.loanStatus === LoanStatus.Active);
    if (primaryInvoice) {
      return primaryInvoice;
    }
    return invoices?.[0];
  }
}
