import { configureStore } from '@reduxjs/toolkit';
import actions from './action';
import { actionsApi } from './api/actionsApi';
import { catsApi } from './api/cats.api';
import { commentsApi } from './api/commentsApi';
import { customerPageCContextApi } from './api/customerPageCContextApi';
import { customerContextApi } from './api/customercontextApi';
import { notificationsApi } from './api/notificationsApi';
import { progressTrackerApi } from './api/progressTrackerApi';
import { usersApi } from './api/usersApi';
import call from './call';
import client from './client';
import common from './common';
import counterParty from './counterparty';
import customerPageCContext from './customerPageCContext';
import customerContext from './customercontext';
import dashboard from './dashboard';
import databaseViewerReducer from './database-viewer';
import email from './email';
import files from './files';
import historyLog from './historyLog';
import invoicePage from './invoicePage';
import language from './language';
import message from './message';
import newWorkflow from './newWorkflow';
import notification from './notification';
import onboarding from './onboarding';
import p2p from './p2p';
import paymentPlans from './paymentPlans';
import payments from './payments';
import predictions from './predictions';
import reports from './report';
import roles from './roles';
import cats from './slices/cats.slice';
import sqsMessage from './sqsMessage';
import strategyBuilder from './strategyBuilder';
import strategyBuilderNew from './strategyBuilderNew';
import teamManagement from './teamManagement';
import teamOverview from './teamOverview';
import template from './template';
import transactions from './transactions';
import users from './users';
import workflow from './workflow';
import tableStateReducer from './slices/tableStateSlice';
import customerContexts from './slices/customer-contexts.slice';
import { customerContextsApi } from './api/customer-contexts.api';
import customers from './slices/customers.slice';
import { customersApi } from './api/customers.api';
import invoices from './slices/invoices.slice';
import { invoicesApi } from './api/invoices.api';
import tasks from './slices/tasks.slice';
import { tasksApi } from './api/tasks.api';
import actionsV2 from './slices/actions.slice';
import { actionsApiV2 } from './api/actions.api';
import { transactionsApiV2 } from './api/transactions.api';
import gatewayTransactions from './slices/gateway-transactions.slice';
import { gatewayTransactionsApi } from './api/gateway-transactions.api';
import paymentPlansV2 from './slices/payment-plans.slice';
import { paymentPlansApi } from './api/payment-plans.api';
import clients from './slices/clients.slice';
import { clientsApi } from './api/clients.api';
import { processCSVApi } from './api/process-CSV.api';

export const store = configureStore({
  reducer: {
    actions,
    call,
    client,
    common,
    counterParty,
    customerContext,
    customerPageCContext,
    dashboard,
    databaseViewerReducer,
    email,
    files,
    historyLog,
    invoicePage,
    language,
    message,
    notification,
    onboarding,
    p2p,
    paymentPlans,
    payments,
    predictions,
    reports,
    roles,
    sqsMessage,
    strategyBuilder,
    teamManagement,
    teamOverview,
    template,
    transactions,
    users,
    workflow,
    strategyBuilderNew,
    newWorkflow,
    cats,
    tableState: tableStateReducer,
    [customerContextApi.reducerPath]: customerContextApi.reducer,
    [customerPageCContextApi.reducerPath]: customerPageCContextApi.reducer,
    [actionsApi.reducerPath]: actionsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [commentsApi.reducerPath]: commentsApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [progressTrackerApi.reducerPath]: progressTrackerApi.reducer,
    [catsApi.reducerPath]: catsApi.reducer,
    customerContexts,
    [customerContextsApi.reducerPath]: customerContextsApi.reducer,
    customers,
    [customersApi.reducerPath]: customersApi.reducer,
    invoices,
    [invoicesApi.reducerPath]: invoicesApi.reducer,
    tasks,
    [tasksApi.reducerPath]: tasksApi.reducer,
    actionsV2,
    [actionsApiV2.reducerPath]: actionsApiV2.reducer,
    [transactionsApiV2.reducerPath]: transactionsApiV2.reducer,
    gatewayTransactions,
    [gatewayTransactionsApi.reducerPath]: gatewayTransactionsApi.reducer,
    paymentPlansV2,
    [paymentPlansApi.reducerPath]: paymentPlansApi.reducer,
    clients,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [processCSVApi.reducerPath]: processCSVApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      customerContextApi.middleware,
      customerPageCContextApi.middleware,
      clientsApi.middleware,
      actionsApi.middleware,
      usersApi.middleware,
      commentsApi.middleware,
      notificationsApi.middleware,
      progressTrackerApi.middleware,
      catsApi.middleware,
      customerContextsApi.middleware,
      customersApi.middleware,
      invoicesApi.middleware,
      tasksApi.middleware,
      actionsApiV2.middleware,
      transactionsApiV2.middleware,
      gatewayTransactionsApi.middleware,
      paymentPlansApi.middleware,
      clientsApi.middleware,
      processCSVApi.middleware,
    ),
});

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
