export const USER_CLICKED_AI_GENERATE_BUTTON = 'user/user_clicked_ai_generate_button';
export const USER_VISITED_PAGE = 'user/user_visited_page';
export const USER_CLICKED_GENERATE_PAYMENT_LINK = 'user/user_clicked_generate_payment_link';
export const REDIRECTED_TO_LOGIN_PAGE = 'system/redirected_to_login_page';
export const USER_TOGGLED_P2P_PAYMENTS = 'user/user_toggled_p2p_payments';
export const USER_APPLIED_CLIENT_FILTERS = 'user/applied_client_filter';
export const SEARCH_QUERY = 'user/searchQuery';
export const USER_CHECKED_PAYMENT_SUMMARY = 'user/user_checked_payment_summary';
export const USER_EXPORTED_CSV_DATA = 'user/user_exported_csv_data';
export const USER_SORTED_COLUMN = 'user/sorted_column';
export const USER_APPLIED_FILTERS = 'user/applied_filter';
export const USER_CHECKED_P2P_DETAILS = 'user/checked_p2p_details';
export const USER_CHECKED_P2P_ASSOCIATED = 'user/checked_p2p_associated';
export const USER_CHECKED_PAYMENTS_AGAINST_P2P = 'user/checked_payments_against_p2p';
export const USER_CHECKED_INVOICE_DETAILS = 'user/checked_invoice_details';
export const USER_CHECKED_INVOICE_AGING_HISTORY = 'user/checked_invoice_aging_history';
export const USER_CHECKED_INVOICE_P2P = 'user/checked_invoice_p2p';
export const USER_CHANGED_PAGINATION = 'user/changed_pagination';
export const USER_CHANGED_RESOLUTION_CENTER_VIEW_MODE = 'user/changed_resolution_center_view_mode';
export const USER_CHECKED_RESOLUTION_CENTER_ACTION_DETAILS = 'user/checked_resolution_center_action_details';
export const USER_CHANGED_RESOLUTION_CENTER_ACTION_STATUS = 'user/changed_resolution_center_action_status';
export const USER_CHANGED_RESOLUTION_CENTER_ACTION_PRIORITY = 'user/changed_resolution_center_action_priority';
export const USER_CHANGED_RESOLUTION_CENTER_ACTION_TYPE = 'user/changed_resolution_center_action_type';
export const USER_CHANGED_RESOLUTION_CENTER_ACTION_ASSIGNEE = 'user/changed_resolution_center_action_assignee';
export const USER_CHANGED_RESOLUTION_CENTER_ACTION_DESCRIPTION = 'user/changed_resolution_center_action_description';
export const USER_MADE_RESOLUTION_CENTER_ACTION_COMMENT = 'user/made_resolution_center_action_comment';
export const USER_TOGGLED_RESOLUTION_CENTER_TABLE = 'user/toggled_resolution_center_table';

export const USER_SORTED_CHATS = 'user/sorted_chats';
export const USER_FILTERED_CHATS = 'user/filtered_chats';
export const USER_CLEARED_CHAT_SORT_FILTER = 'user/cleared_chat_sort_filter';
export const USER_OPENED_CHAT = 'user/opened_chat';
export const USER_SYNCED_CHAT = 'user/synced_chat';
export const USER_TOGGLED_MESSAGE_PIN = 'user/toggled_message_pin';
export const USER_COPIED_CHAT_MESSAGE = 'user/copied_chat_message';
export const USER_TOGGLED_MESSAGE_VISIBLITY = 'user/toggled_message_visiblity';
export const USER_GENERATED_INVOICE_LINK = 'user/generated_invoice_link';
export const USER_DOWNLOADED_INVOICE = 'user/downloaded_invoice';
export const USER_SENT_INVOICE = 'user/sent_invoice';
export const USER_GENERATED_PAYMENT_LINK = 'user/generated_payment_link';
export const USER_ADDED_PAYMENT_METHOD = 'user/added_payment_method';
export const USER_CHECKED_CHAT_SECONDARY_SIDEBAR = 'user/checked_chat_secondary_sidebar';
export const USER_SWITCHED_CHAT_COMMUNICATION_TYPE = 'user/switched_chat_communication_type';
export const USER_OPENED_CHAT_EMAIL_THREAD = 'user/opened_chat_email_thread';
export const USER_INITIATED_CHAT_CALL = 'user/initiated_chat_call';
export const USER_SENT_CHAT_SMS = 'user/sent_chat_message';
export const USER_LOGGED_IN = 'user/logged_in';
export const USER_FAILED_LOGIN = 'user/failed_login';
export const USER_LOGGED_OUT = 'user/logged_out';
export const USER_GOOGLE_LOGIN_SUCCESSFUL = 'user/google_login_successful';
export const USER_PLAYED_CALL_RECORDING = 'user/played_call_recording';
export const USER_CHECKED_CALL_SUMMARY = 'user/checked_call_summary';
export const USER_CHECKED_CALL_TRANSCRIPTION = 'user/checked_call_transcription';
export const USER_CHECKED_CALL_POLARITY_GRAPH = 'user/checked_call_polarity_graph';
export const USER_CHECKED_SMART_CALL_TRANSCRIPTION = 'user/checked_smart_call_transcription';
