import { GenericObject } from '@finance-ops/types';
import { AmplitudeAnalytics } from '../thirdParty';
import {
  REDIRECTED_TO_LOGIN_PAGE,
  SEARCH_QUERY,
  USER_SORTED_COLUMN,
  USER_APPLIED_FILTERS,
  USER_CHECKED_INVOICE_AGING_HISTORY,
  USER_CHECKED_INVOICE_DETAILS,
  USER_CHECKED_INVOICE_P2P,
  USER_CHECKED_P2P_ASSOCIATED,
  USER_CHECKED_P2P_DETAILS,
  USER_CHECKED_PAYMENT_SUMMARY,
  USER_CHECKED_PAYMENTS_AGAINST_P2P,
  USER_CLICKED_AI_GENERATE_BUTTON,
  USER_CLICKED_GENERATE_PAYMENT_LINK,
  USER_EXPORTED_CSV_DATA,
  USER_TOGGLED_P2P_PAYMENTS,
  USER_VISITED_PAGE,
  USER_CHANGED_PAGINATION,
  USER_CHANGED_RESOLUTION_CENTER_VIEW_MODE,
  USER_CHANGED_RESOLUTION_CENTER_ACTION_STATUS,
  USER_CHANGED_RESOLUTION_CENTER_ACTION_PRIORITY,
  USER_CHANGED_RESOLUTION_CENTER_ACTION_TYPE,
  USER_CHANGED_RESOLUTION_CENTER_ACTION_ASSIGNEE,
  USER_CHECKED_RESOLUTION_CENTER_ACTION_DETAILS,
  USER_CHANGED_RESOLUTION_CENTER_ACTION_DESCRIPTION,
  USER_MADE_RESOLUTION_CENTER_ACTION_COMMENT,
  USER_TOGGLED_RESOLUTION_CENTER_TABLE,
  USER_APPLIED_CLIENT_FILTERS,
  USER_SORTED_CHATS,
  USER_FILTERED_CHATS,
  USER_CLEARED_CHAT_SORT_FILTER,
  USER_OPENED_CHAT,
  USER_SYNCED_CHAT,
  USER_TOGGLED_MESSAGE_PIN,
  USER_COPIED_CHAT_MESSAGE,
  USER_TOGGLED_MESSAGE_VISIBLITY,
  USER_GENERATED_INVOICE_LINK,
  USER_DOWNLOADED_INVOICE,
  USER_SENT_INVOICE,
  USER_GENERATED_PAYMENT_LINK,
  USER_ADDED_PAYMENT_METHOD,
  USER_CHECKED_CHAT_SECONDARY_SIDEBAR,
  USER_SWITCHED_CHAT_COMMUNICATION_TYPE,
  USER_OPENED_CHAT_EMAIL_THREAD,
  USER_INITIATED_CHAT_CALL,
  USER_SENT_CHAT_SMS,
  USER_LOGGED_IN,
  USER_FAILED_LOGIN,
  USER_LOGGED_OUT,
  USER_GOOGLE_LOGIN_SUCCESSFUL,
  USER_PLAYED_CALL_RECORDING,
  USER_CHECKED_CALL_SUMMARY,
  USER_CHECKED_CALL_TRANSCRIPTION,
  USER_CHECKED_CALL_POLARITY_GRAPH,
  USER_CHECKED_SMART_CALL_TRANSCRIPTION,
} from './actions/names';

class Track {
  private static instance: Track;
  private pageName: string;
  private previousPageName: string;
  private lastQuery = '';

  constructor() {
    // will add common data here
  }

  static getInstance = (): Track => {
    if (!Track.instance) Track.instance = new Track();
    return Track.instance;
  };

  private getCommonData = () => {
    const data: Record<string, any> = {};
    return data;
  };

  ampEvent = (eventName: string, data = {}) => {
    const dataWithClientInformation = {
      ...data,
    };
    AmplitudeAnalytics?.track(eventName, { data: dataWithClientInformation });
  };

  appliedClientFilter = (clientInformation: { clientId: string; clientName: string }) => {
    this.ampEvent(USER_APPLIED_CLIENT_FILTERS, { clientInformation });
  };

  setUserId = (userId: string | null, quId: string | null) => {
    AmplitudeAnalytics?.setUserId(userId || undefined);
  };

  clickedAIGenerateButton = (data: any) => {
    this.ampEvent(USER_CLICKED_AI_GENERATE_BUTTON, data);
  };

  visitedPage(pageName: string) {
    if (pageName !== this.previousPageName) {
      this.ampEvent(USER_VISITED_PAGE, { pageName });
      this.pageName = pageName;
      this.previousPageName = pageName;
    }
  }

  clickedOnPaymentGenerationLink({ customerId, clientId }: { customerId: string; clientId: string }) {
    this.ampEvent(USER_CLICKED_GENERATE_PAYMENT_LINK, { customerId, clientId });
  }

  redirectedToLoginPage({ reason }: { reason: string }) {
    this.ampEvent(REDIRECTED_TO_LOGIN_PAGE, { reason });
  }

  toggledP2PPayments({ switchedTo }: { switchedTo: 'P2P' | 'Payments' }) {
    this.ampEvent(USER_TOGGLED_P2P_PAYMENTS, { switchedTo });
  }

  searchQuery(query: string) {
    if (this.lastQuery !== query) {
      this.lastQuery = query;
      this.ampEvent(SEARCH_QUERY, { pageName: this.pageName, query });
    }
  }

  changedFilter(filter: GenericObject) {
    this.ampEvent(USER_APPLIED_FILTERS, { pageName: this.pageName, filter });
  }

  filteredChats(filterData: GenericObject) {
    this.ampEvent(USER_FILTERED_CHATS, { filterData });
  }

  sortedColumn(sortedCol: GenericObject) {
    this.ampEvent(USER_SORTED_COLUMN, { pageName: this.pageName, sortedCol });
  }

  sortedChats({ fieldName, order }: { fieldName: string; order: string }) {
    if (fieldName !== '') {
      this.ampEvent(USER_SORTED_CHATS, { fieldName, order });
    }
  }

  exportedCsvData(pageName: string) {
    this.ampEvent(USER_EXPORTED_CSV_DATA, { pageName });
  }

  paginationChanged(paginationData: GenericObject) {
    this.ampEvent(USER_CHANGED_PAGINATION, { pageName: this.pageName, paginationData });
  }

  checkedPaymentSummary({
    customerName,
    customerId,
    paymentId,
  }: {
    customerName: string;
    customerId: string;
    paymentId: string;
  }) {
    this.ampEvent(USER_CHECKED_PAYMENT_SUMMARY, { customerName, customerId, paymentId });
  }

  checkedP2pDetails({ customerName, customerId }: { customerName: string; customerId: string }) {
    this.ampEvent(USER_CHECKED_P2P_DETAILS, { customerName, customerId });
  }

  checkedP2pAssociated({ customerName, customerId }: { customerName: string; customerId: string }) {
    this.ampEvent(USER_CHECKED_P2P_ASSOCIATED, { customerName, customerId });
  }

  checkedP2pPaymentsAgaintP2p({ customerName, customerId }: { customerName: string; customerId: string }) {
    this.ampEvent(USER_CHECKED_PAYMENTS_AGAINST_P2P, { customerName, customerId });
  }

  checkedInvoiceDetails({ invoiceNumber, customerName }: { invoiceNumber: string; customerName: string }) {
    this.ampEvent(USER_CHECKED_INVOICE_DETAILS, { invoiceNumber, customerName });
  }

  checkedInvoiceAgingHistory({ invoiceNumber, customerName }: { invoiceNumber: string; customerName: string }) {
    this.ampEvent(USER_CHECKED_INVOICE_AGING_HISTORY, { invoiceNumber, customerName });
  }

  checkedInvoiceP2p({ invoiceNumber, customerName }: { invoiceNumber: string; customerName: string }) {
    this.ampEvent(USER_CHECKED_INVOICE_P2P, { invoiceNumber, customerName });
  }

  changedResolutionCenterViewMode(viewMode: string) {
    this.ampEvent(USER_CHANGED_RESOLUTION_CENTER_VIEW_MODE, { viewMode });
  }

  checkedResolutionCenterActionDetails(actionId: string) {
    this.ampEvent(USER_CHECKED_RESOLUTION_CENTER_ACTION_DETAILS, { actionId });
  }

  changedResolutionCenterActionStatus({ actionId, status }: { actionId: string; status: string }) {
    this.ampEvent(USER_CHANGED_RESOLUTION_CENTER_ACTION_STATUS, { actionId, status });
  }

  changedResolutionCenterActionPriority({ actionId, priority }: { actionId: string; priority: string }) {
    this.ampEvent(USER_CHANGED_RESOLUTION_CENTER_ACTION_PRIORITY, { actionId, priority });
  }

  changedResolutionCenterActionType({ actionId, type }: { actionId: string; type: string }) {
    this.ampEvent(USER_CHANGED_RESOLUTION_CENTER_ACTION_TYPE, { actionId, type });
  }

  changedResolutionCenterActionAssignee({ actionId, assignee }: { actionId: string; assignee: string }) {
    this.ampEvent(USER_CHANGED_RESOLUTION_CENTER_ACTION_ASSIGNEE, { actionId, assignee });
  }

  changedResolutionCenterActionDescription({ actionId, description }: { actionId: string; description: string }) {
    this.ampEvent(USER_CHANGED_RESOLUTION_CENTER_ACTION_DESCRIPTION, { actionId, description });
  }

  madeResolutionCenterActionComment({ actionId, comment }: { actionId: string; comment: string }) {
    this.ampEvent(USER_MADE_RESOLUTION_CENTER_ACTION_COMMENT, { actionId, comment });
  }

  toggledCollapseResolutionCenter({ tableName, newState }: { tableName: string; newState: string }) {
    this.ampEvent(USER_TOGGLED_RESOLUTION_CENTER_TABLE, { tableName, newState });
  }

  clearedChatSortFilter() {
    this.ampEvent(USER_CLEARED_CHAT_SORT_FILTER);
  }

  openedChat({ customerId, customerName }: { customerId: string; customerName: string }) {
    this.ampEvent(USER_OPENED_CHAT, { customerId, customerName });
  }

  syncedChat({ eventName }: { eventName: string }) {
    this.ampEvent(USER_SYNCED_CHAT, { eventName });
  }

  messagePinned({ isPinned, message }: { isPinned: boolean; message: string }) {
    this.ampEvent(USER_TOGGLED_MESSAGE_PIN, { isPinned, message });
  }

  copiedMessage(messageId: string) {
    this.ampEvent(USER_COPIED_CHAT_MESSAGE, { messageId });
  }

  toggeledMessageVisiblity({ visiblity, messageId }: { visiblity: boolean; messageId: string }) {
    this.ampEvent(USER_TOGGLED_MESSAGE_VISIBLITY, { visiblity, messageId });
  }

  invoiceLinkGenerated({
    customerId,
    clientId,
    clientName,
  }: {
    customerId: string;
    clientId: string;
    clientName: string;
  }) {
    this.ampEvent(USER_GENERATED_INVOICE_LINK, { customerId, clientId, clientName });
  }
  invoiceDownloaded({
    customerId,
    clientId,
    clientName,
  }: {
    customerId: string;
    clientId: string;
    clientName: string;
  }) {
    this.ampEvent(USER_DOWNLOADED_INVOICE, { customerId, clientId, clientName });
  }
  invoiceSentOnEmail({
    customerId,
    clientId,
    clientName,
  }: {
    customerId: string;
    clientId: string;
    clientName: string;
  }) {
    this.ampEvent(USER_SENT_INVOICE, { customerId, clientId, clientName });
  }

  paymentLinkGenerated({ id, clientId }: { id: string; clientId: string }) {
    this.ampEvent(USER_GENERATED_PAYMENT_LINK, { id, clientId });
  }

  paymentMethodAdded({ customerId, clientId }: { customerId: string; clientId: string }) {
    this.ampEvent(USER_ADDED_PAYMENT_METHOD, { customerId, clientId });
  }

  checkedChatSecondarySidebar({
    customerId,
    clientId,
    title,
  }: {
    customerId: string;
    clientId: string;
    title: string;
  }) {
    if (customerId === '') {
      this.ampEvent(USER_CHECKED_CHAT_SECONDARY_SIDEBAR, { title });
    } else {
      this.ampEvent(USER_CHECKED_CHAT_SECONDARY_SIDEBAR, { customerId, clientId, title });
    }
  }

  switchedChatCommunicationType({
    customerId,
    clientId,
    communicationType,
  }: {
    customerId: string;
    clientId: string;
    communicationType: string;
  }) {
    if (customerId === '') {
      this.ampEvent(USER_SWITCHED_CHAT_COMMUNICATION_TYPE, { communicationType });
    } else {
      this.ampEvent(USER_SWITCHED_CHAT_COMMUNICATION_TYPE, { customerId, clientId, communicationType });
    }
  }

  openedChatEmailThread({
    clientId,
    customerId,
    emailThreadId,
  }: {
    clientId: string;
    customerId: string;
    emailThreadId: string;
  }) {
    this.ampEvent(USER_OPENED_CHAT_EMAIL_THREAD, { clientId, customerId, emailThreadId });
  }

  initiatiedChatCall({
    customerId,
    clientId,
    userId,
    type,
  }: {
    customerId: string;
    clientId: string;
    userId: string;
    type: string;
  }) {
    this.ampEvent(USER_INITIATED_CHAT_CALL, { customerId, clientId, userId, type });
  }

  sentChatSms({ message, customerId, clientId }: { message: string; customerId: string; clientId: string }) {
    this.ampEvent(USER_SENT_CHAT_SMS, { message, customerId, clientId });
  }

  loggedIn({ userName }: { userName: string }) {
    this.ampEvent(USER_LOGGED_IN, { userName });
  }

  failedLogin({ errorMessage }: { errorMessage: string }) {
    this.ampEvent(USER_FAILED_LOGIN, { errorMessage });
  }

  loggedOut({ userId, email }: { userId: string; email: string }) {
    this.ampEvent(USER_LOGGED_OUT, { userId, email });
  }

  googleLogInSuccessful({ userName, userId }: { userName: string; userId: string }) {
    this.ampEvent(USER_GOOGLE_LOGIN_SUCCESSFUL, { userName, userId });
  }

  playedCallRecording({ callDate, customerName }: { callDate: string; customerName: string }) {
    this.ampEvent(USER_PLAYED_CALL_RECORDING, { callDate, customerName });
  }

  checkedCallSummary({ callDate, customerName }: { callDate: string; customerName: string }) {
    this.ampEvent(USER_CHECKED_CALL_SUMMARY, { callDate, customerName });
  }

  checkedCallTrascription({ callDate, customerName }: { callDate: string; customerName: string }) {
    this.ampEvent(USER_CHECKED_CALL_TRANSCRIPTION, { callDate, customerName });
  }

  checkedCallPolarityGraph({ callDate, customerName }: { callDate: string; customerName: string }) {
    this.ampEvent(USER_CHECKED_CALL_POLARITY_GRAPH, { callDate, customerName });
  }

  userCheckedSmartTranscript({ callDate, customerName }: { callDate: string; customerName: string }) {
    this.ampEvent(USER_CHECKED_SMART_CALL_TRANSCRIPTION, { callDate, customerName });
  }
}
export { Track };
