import { PaymentMethod } from '../transactions';

export enum CHARGE_TYPE {
  STRIPE_GATEWAY = 'STRIPE_GATEWAY',
  NUVEI_GATEWAY = 'NUVEI',
  INTERNAL_PAYMENT_CHARGE = 'INTERNAL_PAYMENT_CHARGE',
  INTERNAL_DISPUTE_CHARGE = 'INTERNAL_DISPUTE_CHARGE',
  EXTERNAL_DISPUTE_CHARGE = 'EXTERNAL_DISPUTE_CHARGE',
  INTERNAL_ACH_FAILED_CHARGE = 'INTERNAL_ACH_FAILED_CHARGE',
  EXTERNAL_ACH_FAILED_CHARGE = 'EXTERNAL_ACH_FAILED_CHARGE',
  STRIPE_DISPUTE = 'STRIPE_DISPUTE',
  NUVEI_DISPUTE = 'NUVEI_DISPUTE',
  EXTERNAL_DISPUTE = 'EXTERNAL_DISPUTE',
  EXTERNAL = 'EXTERNAL',
}

export enum CHARGE_CALCULATION_TYPE {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
  CAP_LIMIT = 'CAP_LIMIT',
}

export type setOfChargesType = {
  type: CHARGE_CALCULATION_TYPE;
  value: number;
  cap?: number;
};

export type ChargesType = {
  id: string;
  key: CHARGE_TYPE;
  currency: 'usd' | string;
  setOfCharges: setOfChargesType[];
  paymentMethod?: PaymentMethod | string;
  clientIds?: string[];
};
