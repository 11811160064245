const ActivityFailure = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'>
      <rect x='0.677734' y='0.935532' width='11.5573' height='11.5573' rx='5.77864' fill='#DB0000' />
      <path
        d='M4.72604 7.73737C4.53077 7.93264 4.53077 8.24922 4.72604 8.44448C4.9213 8.63974 5.23788 8.63974 5.43314 8.44448L4.72604 7.73737ZM6.80985 7.06777C7.00511 6.87251 7.00511 6.55593 6.80985 6.36066C6.61459 6.1654 6.29801 6.1654 6.10275 6.36066L6.80985 7.06777ZM6.10275 6.36066C5.90748 6.55593 5.90748 6.87251 6.10275 7.06777C6.29801 7.26303 6.61459 7.26303 6.80985 7.06777L6.10275 6.36066ZM8.18656 5.69106C8.38182 5.4958 8.38182 5.17922 8.18656 4.98396C7.9913 4.78869 7.67472 4.78869 7.47945 4.98396L8.18656 5.69106ZM6.80985 6.36066C6.61459 6.1654 6.29801 6.1654 6.10275 6.36066C5.90748 6.55593 5.90748 6.87251 6.10275 7.06777L6.80985 6.36066ZM7.47945 8.44448C7.67472 8.63974 7.9913 8.63974 8.18656 8.44448C8.38182 8.24922 8.38182 7.93264 8.18656 7.73737L7.47945 8.44448ZM6.10275 7.06777C6.29801 7.26303 6.61459 7.26303 6.80985 7.06777C7.00511 6.87251 7.00511 6.55593 6.80985 6.36066L6.10275 7.06777ZM5.43314 4.98396C5.23788 4.78869 4.9213 4.78869 4.72604 4.98396C4.53077 5.17922 4.53077 5.4958 4.72604 5.69106L5.43314 4.98396ZM5.43314 8.44448L6.80985 7.06777L6.10275 6.36066L4.72604 7.73737L5.43314 8.44448ZM6.80985 7.06777L8.18656 5.69106L7.47945 4.98396L6.10275 6.36066L6.80985 7.06777ZM6.10275 7.06777L7.47945 8.44448L8.18656 7.73737L6.80985 6.36066L6.10275 7.06777ZM6.80985 6.36066L5.43314 4.98396L4.72604 5.69106L6.10275 7.06777L6.80985 6.36066Z'
        fill='white'
      />
    </svg>
  );
};

export default ActivityFailure;
