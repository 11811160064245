import {
  TransactionType,
  CreateTransactionRequest,
  GetTransactionsResponse,
  UpdateTransactionMutationRequest,
} from '@finance-ops/collections/transaction-types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { stringify } from 'qs';
import { GetAll } from '@finance-ops/repository';

export const apiController = new AbortController();

const url = '/v2/transactions';

export const transactionsApiV2 = createApi({
  reducerPath: 'transactionsApiV2',
  baseQuery,
  tagTypes: ['Transactions'],
  endpoints: builder => ({
    getTransactions: builder.query<GetTransactionsResponse, GetAll<TransactionType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}?${queryString}`,
        };
      },
      providesTags: ['Transactions'],
    }),
    getTransactionsCount: builder.query<number, GetAll<TransactionType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}/count?${queryString}`,
        };
      },
      providesTags: ['Transactions'],
    }),
    getTransactionById: builder.query<TransactionType, string>({
      query: id => `${url}/${id}`,
      providesTags: (_, __, id) => [{ type: 'Transactions', id }],
    }),
    createTransaction: builder.mutation<TransactionType, CreateTransactionRequest>({
      query: body => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Transactions'],
    }),
    updateTransaction: builder.mutation<TransactionType, UpdateTransactionMutationRequest>({
      query: ({ id, body }) => {
        return {
          url: `${url}/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Transactions', id }, 'Transactions'],
    }),
    deleteTransaction: builder.mutation<void, string>({
      query: id => ({
        url: `${url}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Transactions'],
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useGetTransactionsCountQuery,
  useGetTransactionByIdQuery,
  useCreateTransactionMutation,
  useUpdateTransactionMutation,
  useDeleteTransactionMutation,
} = transactionsApiV2;
