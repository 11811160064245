const FinanceOpsLogo = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='180' height='30' viewBox='0 0 180 30' fill='none'>
      <path
        d='M20.6328 4.37847V0.476562H9.7529H8.59679H5.35552V10.0352H0.9375V13.9577H5.35552V23.3099H9.7529V13.9577H20.0961V10.0352H9.7529V4.37847H20.6328Z'
        fill='#1E1E1E'
      />
      <path
        d='M25.7933 5.34878C25.0913 5.34878 24.5339 5.12168 24.0797 4.64685C23.6255 4.19266 23.3984 3.59395 23.3984 2.91267C23.3984 2.25203 23.6255 1.67397 24.0797 1.19914C24.5339 0.724302 25.112 0.476562 25.7933 0.476562C26.5158 0.476562 27.0939 0.724302 27.5274 1.19914C27.9816 1.67397 28.1881 2.25203 28.1881 2.91267C28.1881 3.6146 27.961 4.19266 27.5274 4.64685C27.0939 5.12168 26.5158 5.34878 25.7933 5.34878ZM23.6668 23.3099V7.61973H27.961V23.3099H23.6668Z'
        fill='#1E1E1E'
      />
      <path
        d='M45.7367 10.4073C45.2206 9.47827 44.498 8.73505 43.5896 8.17763C42.6812 7.59957 41.649 7.31055 40.5135 7.31055C39.3161 7.31055 38.2632 7.57893 37.3135 8.09505C36.7974 8.38408 36.3639 8.7144 35.9716 9.1273V7.64086H31.7188V23.3311H35.9716V14.3298C35.9716 13.7105 36.1161 13.1531 36.3845 12.6782C36.6529 12.2034 37.0245 11.8318 37.4993 11.5841C37.9742 11.3157 38.511 11.1918 39.1303 11.1918C40.0593 11.1918 40.8232 11.4808 41.4013 12.0589C41.9793 12.637 42.2683 13.4008 42.2683 14.3298V23.3311H46.5212V13.4008C46.5212 12.3066 46.2528 11.3157 45.7367 10.4073Z'
        fill='#1E1E1E'
      />
      <path
        d='M61.0754 7.62036V9.1068C60.6418 8.65261 60.1463 8.26035 59.5476 7.97132C58.6599 7.51713 57.6483 7.29004 56.5335 7.29004C55.109 7.29004 53.8083 7.641 52.6729 8.36358C51.5374 9.08615 50.629 10.0565 49.9684 11.2952C49.3077 12.5339 48.998 13.9171 48.998 15.4861C48.998 17.0345 49.3284 18.4177 49.9684 19.6358C50.629 20.8745 51.5167 21.8448 52.6729 22.5673C53.829 23.2899 55.109 23.6409 56.5335 23.6409C57.6689 23.6409 58.6599 23.4138 59.5476 22.939C60.1257 22.6293 60.6418 22.2577 61.0754 21.8035V23.3312H65.3076V7.641L61.0754 7.62036ZM60.1876 18.5209C59.4444 19.3054 58.4947 19.6977 57.2973 19.6977C56.5128 19.6977 55.8315 19.5119 55.2328 19.1609C54.6341 18.8099 54.1799 18.3145 53.829 17.6745C53.4986 17.0345 53.3335 16.2913 53.3335 15.4448C53.3335 14.619 53.4986 13.8964 53.829 13.2564C54.1593 12.6164 54.6341 12.121 55.2328 11.77C55.8315 11.419 56.5128 11.2332 57.2973 11.2332C58.1025 11.2332 58.8044 11.419 59.4031 11.77C60.0018 12.121 60.456 12.6164 60.807 13.2564C61.1373 13.8964 61.3025 14.619 61.3025 15.4448C61.3025 16.7042 60.9309 17.7364 60.1876 18.5209Z'
        fill='#1E1E1E'
      />
      <path
        d='M83.0844 10.4073C82.5682 9.47827 81.8457 8.73505 80.9373 8.17763C80.0289 7.59957 78.9966 7.31055 77.8612 7.31055C76.6638 7.31055 75.6109 7.57893 74.6612 8.09505C74.1451 8.38408 73.7115 8.7144 73.3193 9.1273V7.64086H69.0664V23.3311H73.3193V14.3298C73.3193 13.7105 73.4638 13.1531 73.7322 12.6782C74.0006 12.2034 74.3722 11.8318 74.847 11.5841C75.3218 11.3157 75.8586 11.1918 76.478 11.1918C77.407 11.1918 78.1708 11.4808 78.7489 12.0589C79.327 12.637 79.616 13.4008 79.616 14.3298V23.3311H83.8689V13.4008C83.8689 12.3066 83.6005 11.3157 83.0844 10.4073Z'
        fill='#1E1E1E'
      />
      <path
        d='M94.8517 23.3104C93.2827 23.3104 91.8789 22.9594 90.6195 22.2575C89.3602 21.5556 88.3899 20.6059 87.6673 19.4085C86.9447 18.2111 86.5938 16.8485 86.5938 15.3414C86.5938 13.8137 86.9447 12.4511 87.6673 11.2537C88.3899 10.0563 89.3808 9.08601 90.6402 8.38408C91.8995 7.68215 93.3034 7.31055 94.8517 7.31055C96.0492 7.31055 97.164 7.53764 98.1962 7.97118C99.2078 8.42537 100.096 9.04472 100.839 9.87052L98.1756 12.575C97.7627 12.1208 97.2879 11.7905 96.7305 11.5634C96.173 11.3363 95.5537 11.2124 94.8724 11.2124C94.1085 11.2124 93.4272 11.3776 92.8285 11.7286C92.2298 12.0795 91.755 12.5544 91.404 13.1531C91.0531 13.7518 90.8879 14.4744 90.8879 15.3002C90.8879 16.1053 91.0531 16.8279 91.404 17.4472C91.755 18.0666 92.2298 18.5621 92.8285 18.913C93.4272 19.264 94.1085 19.4292 94.8724 19.4292C95.5743 19.4292 96.1937 19.3053 96.7717 19.0575C97.3498 18.8098 97.804 18.4588 98.2169 17.984L100.921 20.6885C100.137 21.5349 99.2285 22.1749 98.2169 22.6291C97.1846 23.0833 96.0698 23.3104 94.8517 23.3104Z'
        fill='#1E1E1E'
      />
      <path
        d='M116.57 11.0266C115.93 9.87053 115.042 8.96214 113.907 8.3015C112.771 7.64086 111.471 7.31055 109.984 7.31055C108.477 7.31055 107.115 7.66151 105.897 8.38408C104.679 9.08601 103.688 10.0563 102.986 11.2331C102.284 12.4305 101.912 13.7931 101.912 15.3208C101.912 16.8692 102.284 18.2524 103.006 19.4498C103.729 20.6472 104.72 21.5969 105.979 22.2988C107.239 22.9801 108.663 23.3311 110.273 23.3311C111.533 23.3311 112.689 23.104 113.742 22.6704C114.815 22.2162 115.724 21.5762 116.467 20.7091L114.031 18.273C113.556 18.8098 113.019 19.2021 112.379 19.4704C111.739 19.7388 111.037 19.8627 110.253 19.8627C109.386 19.8627 108.622 19.6769 107.961 19.3053C107.3 18.9337 106.805 18.3969 106.454 17.7156C106.309 17.4472 106.206 17.1582 106.124 16.8485L117.334 16.8279C117.416 16.4769 117.478 16.1466 117.499 15.8576C117.54 15.5685 117.54 15.3002 117.54 15.0111C117.54 13.504 117.21 12.1828 116.57 11.0266ZM107.817 11.3157C108.436 10.9441 109.158 10.7583 109.984 10.7583C110.769 10.7583 111.429 10.9234 111.966 11.2537C112.503 11.5841 112.916 12.0589 113.205 12.6576C113.349 12.9466 113.453 13.277 113.535 13.6486L106.103 13.6692C106.186 13.3802 106.289 13.1118 106.413 12.864C106.722 12.2034 107.197 11.6873 107.817 11.3157Z'
        fill='#1E1E1E'
      />
      <path
        d='M144.276 7.37213C143.698 5.98891 142.872 4.77086 141.819 3.73861C140.767 2.70636 139.528 1.90121 138.124 1.32315C136.72 0.745087 135.192 0.456055 133.541 0.456055C131.869 0.456055 130.341 0.745087 128.937 1.32315C127.533 1.90121 126.315 2.70636 125.262 3.73861C125.056 3.94506 124.87 4.15151 124.684 4.35796C123.92 5.24569 123.301 6.23665 122.826 7.35148C122.248 8.7347 121.959 10.2211 121.959 11.8521C121.959 13.4624 122.248 14.9488 122.826 16.3321C123.404 17.7153 124.23 18.9333 125.303 19.9862C126.377 21.0185 127.595 21.8443 129.02 22.4223C130.423 23.0004 131.972 23.2894 133.623 23.2894C135.254 23.2894 136.761 23.0004 138.165 22.4223C139.569 21.8443 140.787 21.0391 141.84 20.0069C142.088 19.7591 142.336 19.4907 142.563 19.2224C143.265 18.3759 143.843 17.4262 144.276 16.394C144.854 15.0108 145.143 13.5037 145.143 11.914C145.164 10.2624 144.875 8.77599 144.276 7.37213ZM137.009 17.963C136.018 18.5411 134.862 18.8301 133.561 18.8301C132.591 18.8301 131.683 18.6649 130.878 18.3346C130.072 18.0043 129.371 17.5295 128.792 16.9101C128.214 16.2908 127.781 15.5682 127.471 14.7011C127.162 13.8547 126.996 12.905 126.996 11.8521C126.996 10.4482 127.265 9.23018 127.802 8.19793C127.967 7.9089 128.132 7.61987 128.318 7.37213C128.792 6.73213 129.391 6.19536 130.093 5.80311C131.084 5.22505 132.24 4.93602 133.541 4.93602C134.511 4.93602 135.42 5.10118 136.204 5.41085C137.009 5.74117 137.69 6.19536 138.289 6.81471C138.867 7.43406 139.321 8.15664 139.631 9.00308C139.941 9.84953 140.106 10.7992 140.106 11.8314C140.106 13.2147 139.838 14.4121 139.301 15.4856C139.177 15.7127 139.053 15.9398 138.909 16.1463C138.413 16.9101 137.773 17.5088 137.009 17.963Z'
        fill='#E052C2'
      />
      <path
        d='M128.317 7.39264C128.792 6.75264 129.391 6.21587 130.093 5.82362C131.084 5.24556 132.24 4.95652 133.54 4.95652V0.476562C131.868 0.476562 130.34 0.765589 128.936 1.34365C127.533 1.92171 126.315 2.72687 125.262 3.75912C125.055 3.96557 124.869 4.17202 124.684 4.37847L128.317 7.39264Z'
        fill='#EBB447'
      />
      <path
        d='M128.318 7.39308L124.684 4.37891C123.92 5.26664 123.301 6.2576 122.826 7.37243C122.248 8.75565 121.959 10.2421 121.959 11.873C121.959 13.4833 122.248 14.9698 122.826 16.353C123.404 17.7362 124.23 18.9543 125.303 20.0072C126.377 21.0394 127.595 21.8652 129.02 22.4433C130.423 23.0213 131.972 23.3104 133.623 23.3104C135.254 23.3104 136.761 23.0213 138.165 22.4433C139.569 21.8652 140.787 21.0601 141.84 20.0278C142.088 19.7801 142.336 19.5117 142.563 19.2433L138.909 16.2085C138.413 16.9517 137.773 17.5504 137.009 17.984C136.018 18.562 134.862 18.851 133.541 18.851C132.571 18.851 131.662 18.6859 130.857 18.3556C130.052 18.0253 129.35 17.5504 128.772 16.9311C128.194 16.3117 127.76 15.5891 127.451 14.722C127.141 13.8756 126.976 12.9259 126.976 11.873C126.976 10.4692 127.244 9.25113 127.781 8.21888C127.967 7.92985 128.132 7.66146 128.318 7.39308Z'
        fill='#8152E0'
      />
      <path
        d='M161.018 8.36358C159.862 7.641 158.582 7.29004 157.137 7.29004C156.022 7.29004 155.01 7.53778 154.102 8.01261C153.565 8.30164 153.111 8.63196 152.698 9.04486V7.66165H148.445V29.5453H152.657V21.9067C153.07 22.299 153.545 22.6499 154.081 22.9183C154.99 23.3725 156.001 23.6202 157.137 23.6202C158.582 23.6202 159.883 23.2693 161.018 22.5467C162.174 21.8241 163.062 20.8538 163.723 19.6358C164.383 18.3971 164.714 17.0138 164.714 15.4861C164.714 13.9377 164.383 12.5339 163.723 11.3158C163.062 10.0565 162.174 9.08615 161.018 8.36358ZM159.862 17.6538C159.532 18.2938 159.057 18.7893 158.458 19.1403C157.859 19.4912 157.158 19.677 156.373 19.677C155.588 19.677 154.907 19.4912 154.308 19.1403C153.71 18.7893 153.256 18.2938 152.905 17.6538C152.574 17.0138 152.409 16.2706 152.409 15.4242C152.409 14.5984 152.574 13.8758 152.905 13.2358C153.235 12.5958 153.71 12.1003 154.308 11.7494C154.907 11.3984 155.588 11.2126 156.352 11.2126C157.137 11.2126 157.818 11.3984 158.437 11.7494C159.036 12.1003 159.511 12.5958 159.841 13.2358C160.172 13.8758 160.337 14.5984 160.337 15.4242C160.378 16.2706 160.213 17.0138 159.862 17.6538Z'
        fill='#1E1E1E'
      />
      <path
        d='M176.895 14.7222C176.317 14.3713 175.698 14.0822 175.058 13.8758C174.418 13.6693 173.798 13.5042 173.22 13.339C172.622 13.1739 172.147 12.9881 171.775 12.7816C171.403 12.5752 171.218 12.2655 171.218 11.8939C171.218 11.5636 171.383 11.2952 171.713 11.1094C172.043 10.9236 172.498 10.8203 173.117 10.8203C173.798 10.8203 174.418 10.9442 174.996 11.1919C175.574 11.4397 176.09 11.8526 176.565 12.4306L179.063 9.9326C178.381 9.04487 177.535 8.38422 176.523 7.95068C175.512 7.51713 174.356 7.29004 173.096 7.29004C171.899 7.29004 170.846 7.49649 169.958 7.88874C169.071 8.281 168.389 8.83841 167.914 9.56098C167.44 10.2836 167.213 11.1094 167.213 12.0797C167.213 13.0087 167.398 13.7519 167.77 14.33C168.142 14.908 168.616 15.3622 169.215 15.6925C169.814 16.0229 170.412 16.2913 171.052 16.4771C171.692 16.6835 172.312 16.8487 172.89 17.0138C173.468 17.179 173.963 17.3648 174.335 17.5919C174.707 17.819 174.892 18.1493 174.892 18.5622C174.892 18.9338 174.727 19.2228 174.376 19.4086C174.025 19.5945 173.509 19.6977 172.849 19.6977C172.002 19.6977 171.238 19.5532 170.536 19.2641C169.834 18.9751 169.236 18.5209 168.699 17.9429L166.242 20.4409C166.738 21.0396 167.357 21.5351 168.038 21.948C168.74 22.3609 169.504 22.6706 170.33 22.9183C171.156 23.1454 172.002 23.2693 172.869 23.2693C174.727 23.2693 176.193 22.8357 177.267 21.9686C178.34 21.1015 178.877 19.9041 178.877 18.4177C178.877 17.4887 178.691 16.7248 178.32 16.1467C177.969 15.548 177.494 15.0732 176.895 14.7222Z'
        fill='#1E1E1E'
      />
    </svg>
  );
};

export default FinanceOpsLogo;
