const OfflineIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='37' height='37' viewBox='0 0 37 37' fill='none'>
      <rect
        x='0.82959'
        y='0.540131'
        width='35.3481'
        height='35.3481'
        rx='17.674'
        stroke='#A3A3A3'
        strokeOpacity='0.38'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5037 13.2142V23.2142C13.5037 24.3187 14.3991 25.2142 15.5037 25.2142H23.5037C24.6082 25.2142 25.5037 24.3187 25.5037 23.2142V13.2142C25.5037 12.1096 24.6082 11.2142 23.5037 11.2142H15.5037C14.3991 11.2142 13.5037 12.1096 13.5037 13.2142Z'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.5037 16.2142C21.5037 17.3187 20.6082 18.2142 19.5037 18.2142C18.3991 18.2142 17.5037 17.3187 17.5037 16.2142C17.5037 15.1096 18.3991 14.2142 19.5037 14.2142C20.6082 14.2142 21.5037 15.1096 21.5037 16.2142Z'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.0547 22.6134C12.7229 22.8613 12.6549 23.3313 12.9029 23.6631C13.1508 23.9949 13.6208 24.0629 13.9526 23.815L13.0547 22.6134ZM25.0547 23.815C25.3866 24.0629 25.8565 23.9949 26.1045 23.6631C26.3524 23.3313 26.2844 22.8613 25.9526 22.6134L25.0547 23.815ZM11.5037 14.4642C11.0894 14.4642 10.7537 14.8 10.7537 15.2142C10.7537 15.6284 11.0894 15.9642 11.5037 15.9642V14.4642ZM13.5037 15.9642C13.9179 15.9642 14.2537 15.6284 14.2537 15.2142C14.2537 14.8 13.9179 14.4642 13.5037 14.4642V15.9642ZM11.5037 17.4642C11.0894 17.4642 10.7537 17.8 10.7537 18.2142C10.7537 18.6284 11.0894 18.9642 11.5037 18.9642V17.4642ZM13.5037 18.9642C13.9179 18.9642 14.2537 18.6284 14.2537 18.2142C14.2537 17.8 13.9179 17.4642 13.5037 17.4642V18.9642ZM11.5037 20.4642C11.0894 20.4642 10.7537 20.8 10.7537 21.2142C10.7537 21.6284 11.0894 21.9642 11.5037 21.9642V20.4642ZM13.5037 21.9642C13.9179 21.9642 14.2537 21.6284 14.2537 21.2142C14.2537 20.8 13.9179 20.4642 13.5037 20.4642V21.9642ZM13.9526 23.815C17.2446 21.3552 21.7628 21.3552 25.0547 23.815L25.9526 22.6134C22.1281 19.7557 16.8792 19.7557 13.0547 22.6134L13.9526 23.815ZM11.5037 15.9642H13.5037V14.4642H11.5037V15.9642ZM11.5037 18.9642H13.5037V17.4642H11.5037V18.9642ZM11.5037 21.9642H13.5037V20.4642H11.5037V21.9642Z'
        fill='#3E3E3E'
      />
    </svg>
  );
};

export default OfflineIcon;
