export enum WebSocketMessageType {
  BROADCAST_DOCUMENT = 'BROADCAST_DOCUMENT',
  CUSTOMER_CONTEXT_CLONE = 'CUSTOMER_CONTEXT_CLONE',
  CUSTOMER_CONTEXT_GET = 'CUSTOMER_CONTEXT_GET',
  CUSTOMER_CONTEXT_GET_FOR_ROUTED = 'CUSTOMER_CONTEXT_GET_FOR_ROUTED',
  CUSTOMER_CONTEXT_UPDATE = 'CUSTOMER_CONTEXT_UPDATE',
  CUSTOMER_GET_BY_ID = 'CUSTOMER_GET_BY_ID',
  CUSTOMER_UPDATE_CONTACT_STATUS = 'CUSTOMER_UPDATE_CONTACT_STATUS',
  CUSTOMER_UPDATE_DND = 'CUSTOMER_UPDATE_DND',
  CUSTOMER_UPDATE_LANGUAGE = 'CUSTOMER_UPDATE_LANGUAGE',
  DASHBOARD_GET_TEAM_OVERVIEW_GRAPH = 'DASHBOARD_GET_TEAM_OVERVIEW_GRAPH',
  GET_ACTIONS_BY_CUSTOMER = 'GET_ACTIONS_BY_CUSTOMER',
  GET_ACTIONS_BY_CUSTOMER_IDS = 'GET_ACTIONS_BY_CUSTOMER_IDS',
  GET_COUNTERPARTY_AMOUNT_GRAPH_DATA = 'GET_COUNTERPARTY_AMOUNT_GRAPH_DATA',
  GET_DASHBOARD_AGG = 'GET_DASHBOARD_AGG',
  GET_DASHBOARD_GRAPH = 'GET_DASHBOARD_GRAPH',
  GET_DASHBOARD_SUMMARY = 'GET_DASHBOARD_SUMMARY',
  GET_DASHBOARD_SYNC = 'GET_DASHBOARD_SYNC',
  GET_DASHBOARD_TYPE_COUNTS = 'GET_DASHBOARD_TYPE_COUNTS',
  GET_EMAIL_ATTACHMENT = 'GET_EMAIL_ATTACHMENT',
  GET_EMAIL_BY_CUSTOMER_IDS = 'GET_EMAIL_BY_CUSTOMER_IDS',
  GET_INVOICES_FOR_INVOICE_PAGE = 'GET_INVOICES_FOR_INVOICE_PAGE',
  GET_QUICKSIGHT_EMBED_URL = 'GET_QUICKSIGHT_EMBED_URL',
  GET_SPECIFIC_USER = 'GET_SPECIFIC_USER',
  HISTORY_LOGS_GET_CUSTOMER_ID = 'HISTORY_LOGS_GET_CUSTOMER_ID',
  INVOICE_UPDATE = 'INVOICE_UPDATE',
  MANAGE_GENERAL_SETTINGS = 'MANAGE_GENERAL_SETTINGS',
  MANAGE_PERSONAL_SETTINGS = 'MANAGE_PERSONAL_SETTINGS',
  MANAGE_USER = 'MANAGE_USER',
  MESSAGE_DELIVERY_STATUS = 'MESSAGE_DELIVERY_STATUS',
  MESSAGE_GET_BY_CUSTOMER_ID = 'MESSAGE_GET_BY_CUSTOMER_ID',
  MESSAGE_GET_BY_CUSTOMER_IDS = 'MESSAGE_GET_BY_CUSTOMER_IDS',
  MESSAGE_RECEIVED = 'MESSAGE_RECEIVED',
  MESSAGE_SEND = 'MESSAGE_SEND',
  MESSAGE_SEND_PLAYGROUND = 'MESSAGE_SEND_PLAYGROUND',
  MESSAGE_SEND_RETRY = 'MESSAGE_SEND_RETRY',
  MESSAGE_SEND_WHATSAPP_TEMPLATE = 'MESSAGE_SEND_WHATSAPP_TEMPLATE',
  MESSAGE_SENT_STATUS = 'MESSAGE_SENT_STATUS',
  MESSAGE_UPDATE = 'MESSAGE_UPDATE',
  NOTE_CREATE_CLIENT = 'NOTE_CREATE_CLIENT',
  NOTE_CREATE_INFO = 'NOTE_CREATE_INFO',
  NOTE_CREATE_INTERNAL = 'NOTE_CREATE_INTERNAL',
  NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  READ_EMAIL = 'READ_EMAIL',
  RESET_DEMO_CUSTOMER = 'RESET_DEMO_CUSTOMER',
  SEARCH_CUSTOMER_TASKS = 'SEARCH_CUSTOMER_TASKS',
  SEND_EMAIL = 'SEND_EMAIL',
  SERVER_ERROR = 'SERVER_ERROR',
  SQS_ERROR_DISCARD = 'SQS_ERROR_DISCARD',
  SQS_ERROR_GET_ALL = 'SQS_ERROR_GET_ALL',
  SQS_ERROR_PROCESS = 'SQS_ERROR_PROCESS',
  STRIPE_PAYMENT = 'STRIPE_PAYMENT',
  TASK_UPDATE = 'TASK_UPDATE',
  TASK_UPDATE_MULTI = 'TASK_UPDATE_MULTI',
  TEAM_OVERVIEW_GET_AGENT_COLLECTION_PROFILE = 'TEAM_OVERVIEW_GET_AGENT_COLLECTION_PROFILE',
  TEAM_OVERVIEW_GET_AGENT_STATISTICS = 'TEAM_OVERVIEW_GET_AGENT_STATISTICS',
  TRANSACTION_ATTACHMENT_UPLOAD = 'TRANSACTION_ATTACHMENT_UPLOAD',
  TRANSLATION_REQUEST = 'TRANSLATION_REQUEST',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
  WHATSAPP_TEMPLATE_GET_ALL = 'WHATSAPP_TEMPLATE_GET_ALL',
  ENTITY_CHANGE = 'ENTITY_CHANGE',
}

export enum EntityChangeType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
}

export interface EntityChangePayload<T> {
  type: EntityChangeType;
  entity: string; // e.g., 'comment', 'action', etc.
  data: T;
  clientId?: string;
  metadata?: {
    referenceId?: string; // For filtering/targeting specific views
    clientId?: string; // For access control
    tags?: string[]; // For cache invalidation
  };
}

export interface WebsocketMessage<T> {
  type: WebSocketMessageType;
  payload: T;
  requestId?: string;
}
