import { PAGINATION } from '@finance-ops/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InvoicesState {
  pagination: {
    page: number;
    limit: number;
  };
  search: string;
}

const initialState: InvoicesState = {
  pagination: {
    page: PAGINATION.PAGE_NUMBER,
    limit: PAGINATION.PAGE_SIZE,
  },
  search: '',
};

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.pagination.limit = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      // Reset to first page when search changes
      state.pagination.page = PAGINATION.PAGE_NUMBER;
    },
    resetSearch: state => {
      state.search = '';
      // Reset to first page when search is cleared
      state.pagination.page = PAGINATION.PAGE_NUMBER;
    },
  },
});

export const { setPage, setLimit, setSearch, resetSearch } = invoicesSlice.actions;
export default invoicesSlice.reducer;
