// ** React Imports
import { ReactNode, useEffect } from 'react';

// ** Next Imports
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';

// ** Store Imports
import { store } from 'apps/webapp/src/store';
import { Provider } from 'react-redux';

// ** Loader Import
import NProgress from 'nprogress';

// ** Emotion Imports
import type { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

import { ToastContainer } from 'apps/webapp/src/notification/ToastContainer';

// ** Config Imports
import { defaultPermission } from 'apps/webapp/src/configs/acl';
import themeConfig from 'apps/webapp/src/configs/themeConfig';

// ** Third Party Import
import { Toaster } from 'react-hot-toast';

// ** Component Imports
import AuthGuard from 'apps/webapp/src/@core/components/auth/AuthGuard';
import GuestGuard from 'apps/webapp/src/@core/components/auth/GuestGuard';
import WindowWrapper from 'apps/webapp/src/@core/components/window-wrapper';
import ThemeComponent from 'apps/webapp/src/@core/theme/ThemeComponent';
import UserLayout from 'apps/webapp/src/layouts/UserLayout';

// ** Spinner Import
import Spinner from 'apps/webapp/src/@core/components/spinner';

// ** Contexts
import { SettingsConsumer, SettingsProvider } from 'apps/webapp/src/@core/context/settingsContext';
import { AuthProvider } from 'apps/webapp/src/context/AuthContext';

// ** Styled Components
import ReactHotToast from 'apps/webapp/src/@core/styles/libs/react-hot-toast';

// ** Utils Imports
import { createEmotionCache } from 'apps/webapp/src/@core/utils/create-emotion-cache';

// ** Prismjs Styles
import 'prismjs';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-tsx';
import 'prismjs/themes/prism-tomorrow.css';

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css';

import 'react-datepicker/dist/react-datepicker.css';

import 'apps/webapp/src/iconify-bundle/icons-bundle-react';

// ** Global css styles
import WebsocketMessageHandlers from 'apps/webapp/src/websocket/WebsocketMessageHandlers';
import '../../styles/globals.css';
import AclGuard from '../@core/components/auth/AclGuard';

// ** Internationalization
import 'apps/webapp/src/configs/i18n';

// ** Tracking
import { Track } from 'apps/webapp/src/@core/track';
import { MicrosoftClarity } from '../@core/thirdParty/MicrosoftClarity';
import { HubSpot } from '../@core/thirdParty/hubspot';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { usePathname } from 'next/navigation';
import OfflineBanner from '../@core/components/offline-banner';
import { InteractionProvider } from '../context/InteractionContext';
import CustomerLayout from '../layouts/CustomerLayout';
import { isPublicPage } from '../utils/page-utils';
import { setupHttpInterceptor } from '../utils/pglite/http-interceptor';
import Devtool from '../views/devtools/Devtool';
import { devtoolsEnabled } from '../utils/pglite/config';
import { GlobalProcessingBar } from '../components/GlobalProcessingBar';
// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};

type GuardProps = {
  isAuthenticationRequired: boolean;
  allowUnauthorizedAccess: boolean;
  children: ReactNode;
};

declare global {
  interface Window {
    clarity: any;
  }
}

const clientSideEmotionCache = createEmotionCache();

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start();
  });
  Router.events.on('routeChangeError', () => {
    NProgress.done();
  });
  Router.events.on('routeChangeComplete', () => {
    NProgress.done();
  });
}

const Guard = ({ children, isAuthenticationRequired, allowUnauthorizedAccess }: GuardProps) => {
  if (allowUnauthorizedAccess) {
    return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>;
  } else if (!allowUnauthorizedAccess && !isAuthenticationRequired) {
    return <>{children}</>;
  } else {
    return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>;
  }
};

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();
  useEffect(() => {
    Track.getInstance().visitedPage(router.pathname);
  }, [router.pathname]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENABLE_DEVTOOL === 'true') {
      setupHttpInterceptor();
    }
  }, []);

  // Variables
  const pathname = usePathname();
  const contentHeightFixed = Component.contentHeightFixed ?? false;
  const getLayout =
    Component.getLayout ??
    (page => {
      return <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>;
    });

  const setConfig = Component.setConfig ?? undefined;

  const isAuthenticationRequired = Component.isAuthenticationRequired ?? true;

  const allowUnauthorizedAccess = Component.allowUnauthorizedAccess ?? false;

  const componentPermission = Component.permission ?? defaultPermission;

  if (pathname && isPublicPage(pathname)) {
    return (
      <Provider store={store}>
        <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
          <SettingsConsumer>
            {({ settings }) => {
              return (
                <ThemeComponent settings={settings}>
                  <Head>
                    <title>{`${themeConfig.templateName}`}</title>
                    <meta name='description' content={`${themeConfig.templateName}`} />
                    <meta name='keywords' content='Financeops, FInance, Collections, Collection Automation, AI' />
                    <meta name='viewport' content='initial-scale=1, width=device-width' />
                  </Head>
                  <AuthProvider>
                    <AclGuard permission={componentPermission} allowUnauthorizedAccess={allowUnauthorizedAccess}>
                      <CustomerLayout>
                        <GlobalProcessingBar />
                        <Component {...pageProps} />
                        <Devtool />
                      </CustomerLayout>
                    </AclGuard>
                  </AuthProvider>
                </ThemeComponent>
              );
            }}
          </SettingsConsumer>
        </SettingsProvider>
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <MicrosoftClarity />
      <HubSpot />
      <CacheProvider value={emotionCache}>
        <Head>
          <title>{`${themeConfig.templateName}`}</title>
          <meta name='description' content={`${themeConfig.templateName}`} />
          <meta name='keywords' content='Financeops, FInance, Collections, Collection Automation, AI' />
          <meta name='viewport' content='initial-scale=1, width=device-width' />
        </Head>

        <AuthProvider>
          <InteractionProvider>
            <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
              <SettingsConsumer>
                {({ settings }) => {
                  return (
                    <ThemeComponent settings={settings}>
                      <WindowWrapper>
                        <Guard
                          isAuthenticationRequired={isAuthenticationRequired}
                          allowUnauthorizedAccess={allowUnauthorizedAccess}
                        >
                          <WebsocketMessageHandlers />
                          {/* <ReduxSync> */}
                          <AclGuard permission={componentPermission} allowUnauthorizedAccess={allowUnauthorizedAccess}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <GlobalProcessingBar />
                              {getLayout(<Component {...pageProps} />)}
                              {devtoolsEnabled && <Devtool />}
                            </LocalizationProvider>
                          </AclGuard>
                          {/* </ReduxSync> */}
                        </Guard>
                      </WindowWrapper>
                      <ReactHotToast>
                        <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                      </ReactHotToast>
                      <ToastContainer />
                      <OfflineBanner></OfflineBanner>
                    </ThemeComponent>
                  );
                }}
              </SettingsConsumer>
            </SettingsProvider>
          </InteractionProvider>
        </AuthProvider>
      </CacheProvider>
    </Provider>
  );
};

export default App;
