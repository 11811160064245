import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { safeLocalStorage } from 'apps/webapp/src/hooks/useLocalStorage';
import authConfig from '../../configs/auth';

const getStoredToken = () => {
  return safeLocalStorage.getItem(authConfig.storageTokenKeyName)!;
};

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getStoredToken();
    if (token) {
      headers.set('Authorization', token);
    }
    return headers;
  },
});
