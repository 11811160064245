import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Call, Device } from '@twilio/voice-sdk';
import { TransformedCall } from '../pages/chat-beta/components/call-container/CallHistory';
import { ConversationAnalysis } from '@finance-ops/types';

export type PreCallData = {
  overallSummary: {
    aiRecommendation: string;
    pastCallsSummary: string;
  };
  dateWiseSummary: { date: string; summary: string }[];
};

export type LiveTranscriptWithTimestamps = {
  role: string;
  message: string;
  timestamp: string;
};

type CallSlice = {
  name: string;
  callToNumber: string;
  activeCall: boolean;
  device: Device | undefined;
  call: Call | undefined;
  voiceCallHistory: TransformedCall[];
  showSingleVoiceCallForDemo: boolean;
  isVoiceCallSelected: boolean;
  conversationAnalysis: ConversationAnalysis;
  typeOfCall: 'ai' | 'voip';
  preCallData: PreCallData;
  liveTranscriptWithTimestamps: LiveTranscriptWithTimestamps[];
};

//test hotfix

const initialState: CallSlice = {
  name: '',
  callToNumber: '',
  device: undefined,
  call: undefined,
  activeCall: false,
  voiceCallHistory: [],
  showSingleVoiceCallForDemo: true,
  isVoiceCallSelected: true,
  conversationAnalysis: {
    polarityScoreCustomer: 0,
    polarityScoreCollector: 0,
    intentToPayScore: 0,
    customerBehavior: 'cooperative',
    collectorBehavior: 'cooperative',
    reason: '',
  },
  typeOfCall: 'voip',
  preCallData: {
    overallSummary: {
      aiRecommendation: '',
      pastCallsSummary: '',
    },
    dateWiseSummary: [],
  },
  liveTranscriptWithTimestamps: [],
};

const callSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    setActiveCall: (state, action: PayloadAction<boolean>) => {
      state.activeCall = action.payload;
    },
    setDevice: (state, action: PayloadAction<Device | undefined>) => {
      state.device = action.payload;
    },
    setCall: (state, action: PayloadAction<Call | undefined>) => {
      state.call = action.payload;
    },
    setCallToNumber: (state, action: PayloadAction<string>) => {
      state.callToNumber = action.payload;
    },
    setCallName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setVoiceCallHistory: (state, action: PayloadAction<TransformedCall[]>) => {
      state.voiceCallHistory = action.payload;
    },
    setShowSingleVoiceCallForDemo: (state, action: PayloadAction<boolean>) => {
      state.showSingleVoiceCallForDemo = action.payload;
    },

    setIsVoiceCallSelected: (state, action: PayloadAction<boolean>) => {
      state.isVoiceCallSelected = action.payload;
    },

    setConversationAnalysis: (state, action: PayloadAction<ConversationAnalysis>) => {
      state.conversationAnalysis = action.payload;
    },

    resetConversationAnalysis: state => {
      state.conversationAnalysis = initialState.conversationAnalysis;
    },

    setTypeOfCall: (state, action: PayloadAction<'ai' | 'voip'>) => {
      state.typeOfCall = action.payload;
    },
    setPreCallData: (state, action: PayloadAction<PreCallData>) => {
      state.preCallData = action.payload;
    },
    setLiveTranscriptWithTimestamps: (state, action: PayloadAction<LiveTranscriptWithTimestamps[]>) => {
      state.liveTranscriptWithTimestamps = action.payload;
    },
  },
});

export const {
  setActiveCall,
  setDevice,
  setCall,
  setCallToNumber,
  setCallName,
  setVoiceCallHistory,
  setShowSingleVoiceCallForDemo,
  setIsVoiceCallSelected,
  setConversationAnalysis,
  resetConversationAnalysis,
  setTypeOfCall,
  setPreCallData,
  setLiveTranscriptWithTimestamps,
} = callSlice.actions;

export default callSlice.reducer;
