import { Types } from 'mongoose';
import { CommonType } from '../common';

export enum PaymentLinkStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export type PaymentLinkType = CommonType & {
  clientId: Types.ObjectId;
  customerId?: Types.ObjectId;
  invoiceId?: Types.ObjectId;
  amount?: number;
  paymentGateway?: string;
  isVariableAmount?: boolean;
  isAggregatedAmount?: boolean;
  metadata?: any;
  status?: PaymentLinkStatus;
  generatedBy?: string;
};
