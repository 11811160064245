const CopyIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='10' height='9' viewBox='0 0 10 9' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.83548 1.62705C8.83548 2.2197 8.08804 2.70026 7.16603 2.70026C6.24402 2.70026 5.49658 2.2197 5.49658 1.62705C5.49658 1.0344 6.24402 0.553833 7.16603 0.553833C8.08804 0.553833 8.83548 1.0344 8.83548 1.62705Z'
        stroke='#6461F3'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5835 1.62701C7.58865 1.77948 7.51025 1.92263 7.379 2.0004C7.24774 2.07816 7.08453 2.07816 6.95328 2.0004C6.82202 1.92263 6.74362 1.77948 6.74877 1.62701C6.74362 1.47454 6.82202 1.33138 6.95328 1.25362C7.08453 1.17586 7.24774 1.17586 7.379 1.25362C7.51025 1.33138 7.58865 1.47454 7.5835 1.62701V1.62701Z'
        stroke='#6461F3'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.69252 0.928833C4.89962 0.928833 5.06752 0.76094 5.06752 0.553833C5.06752 0.346726 4.89962 0.178833 4.69252 0.178833V0.928833ZM3.18046 0.553833V0.178832L3.17955 0.178834L3.18046 0.553833ZM1.16456 2.57891H1.53956L1.53955 2.57811L1.16456 2.57891ZM1.16456 5.61653L1.53956 5.61734V5.61653H1.16456ZM3.18046 7.64161L3.17955 8.01661H3.18046V7.64161ZM6.20458 7.64161V8.01662L6.20548 8.01661L6.20458 7.64161ZM8.22048 5.61653H7.84547L7.84548 5.61734L8.22048 5.61653ZM8.59548 3.59145C8.59548 3.38435 8.42758 3.21645 8.22048 3.21645C8.01337 3.21645 7.84548 3.38435 7.84548 3.59145H8.59548ZM2.67651 5.74806C2.4694 5.74806 2.30151 5.91595 2.30151 6.12306C2.30151 6.33017 2.4694 6.49806 2.67651 6.49806V5.74806ZM6.70885 6.49806C6.91595 6.49806 7.08385 6.33017 7.08385 6.12306C7.08385 5.91595 6.91595 5.74806 6.70885 5.74806V6.49806ZM2.67651 3.72272C2.4694 3.72272 2.30151 3.89061 2.30151 4.09772C2.30151 4.30482 2.4694 4.47272 2.67651 4.47272V3.72272ZM6.70885 4.47272C6.91595 4.47272 7.08385 4.30482 7.08385 4.09772C7.08385 3.89061 6.91595 3.72272 6.70885 3.72272V4.47272ZM4.69252 0.178833H3.18046V0.928833H4.69252V0.178833ZM3.17955 0.178834C1.85666 0.182013 0.786724 1.25682 0.789556 2.57972L1.53955 2.57811C1.53761 1.66935 2.2726 0.931016 3.18136 0.928832L3.17955 0.178834ZM0.789556 2.57891V5.61653H1.53956V2.57891H0.789556ZM0.789556 5.61573C0.786724 6.93862 1.85666 8.01343 3.17955 8.01661L3.18136 7.26662C2.2726 7.26443 1.53761 6.52609 1.53955 5.61734L0.789556 5.61573ZM3.18046 8.01661H6.20458V7.26661H3.18046V8.01661ZM6.20548 8.01661C7.52837 8.01343 8.59831 6.93862 8.59547 5.61573L7.84548 5.61734C7.84742 6.52609 7.11243 7.26443 6.20367 7.26662L6.20548 8.01661ZM8.59548 5.61653V3.59145H7.84548V5.61653H8.59548ZM2.67651 6.49806H6.70885V5.74806H2.67651V6.49806ZM2.67651 4.47272H6.70885V3.72272H2.67651V4.47272Z'
        fill='#6461F3'
      />
    </svg>
  );
};

export default CopyIcon;
