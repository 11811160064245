const TimelineRoundedIcon = () => {
  return (
    <svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='27.6115' height='27.6115' rx='13.8058' stroke='#CECECE' />
      <path
        d='M11.334 11.3477C11.0578 11.3477 10.834 11.5715 10.834 11.8477C10.834 12.1238 11.0578 12.3477 11.334 12.3477V11.3477ZM17.0837 11.8477L17.1228 11.3492C17.1098 11.3482 17.0967 11.3477 17.0837 11.3477V11.8477ZM18.1372 13.0679L17.6383 13.0339C17.6368 13.0564 17.6368 13.0791 17.6383 13.1016L18.1372 13.0679ZM17.0837 14.2876V14.7876C17.0967 14.7876 17.1098 14.7871 17.1228 14.786L17.0837 14.2876ZM12.3875 14.2876V13.7876C12.3745 13.7876 12.3614 13.7881 12.3484 13.7891L12.3875 14.2876ZM11.334 15.5078L11.8328 15.5415C11.8344 15.519 11.8344 15.4964 11.8328 15.4738L11.334 15.5078ZM12.3875 16.7275L12.3484 17.226C12.3614 17.227 12.3745 17.2275 12.3875 17.2275L12.3875 16.7275ZM18.1372 17.2275C18.4133 17.2275 18.6372 17.0036 18.6372 16.7275C18.6372 16.4513 18.4133 16.2275 18.1372 16.2275V17.2275ZM11.334 12.3477H17.0837V11.3477H11.334V12.3477ZM17.0445 12.3461C17.3975 12.3738 17.6624 12.6806 17.6383 13.0339L18.636 13.1018C18.6973 12.2016 18.0224 11.4198 17.1228 11.3492L17.0445 12.3461ZM17.6383 13.1016C17.6622 13.4548 17.3974 13.7614 17.0445 13.7891L17.1228 14.786C18.0221 14.7154 18.697 13.9341 18.636 13.0341L17.6383 13.1016ZM17.0837 13.7876H12.3875V14.7876H17.0837V13.7876ZM12.3484 13.7891C11.4488 13.8597 10.7738 14.6415 10.8351 15.5418L11.8328 15.4738C11.8088 15.1205 12.0736 14.8138 12.4266 14.786L12.3484 13.7891ZM10.8351 15.474C10.7742 16.374 11.4491 17.1553 12.3484 17.226L12.4267 16.229C12.0738 16.2013 11.8089 15.8947 11.8328 15.5415L10.8351 15.474ZM12.3875 17.2275H18.1372V16.2275H12.3875V17.2275Z'
        fill='#6461F3'
      />
      <circle cx='10.6997' cy='11.779' r='0.5' fill='#6461F3' stroke='#6461F3' strokeWidth='0.474027' />
      <circle cx='17.9121' cy='16.8327' r='0.5' stroke='#6461F3' strokeWidth='0.474027' />
    </svg>
  );
};

export default TimelineRoundedIcon;
