import {
  PaymentPlanType,
  CreatePaymentPlanRequest,
  GetPaymentPlansResponse,
  UpdatePaymentPlanMutationRequest,
} from '@finance-ops/collections/payment-plan-types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { GetAll } from '@finance-ops/repository';
import { stringify } from 'qs';

export const apiController = new AbortController();

const url = '/v2/payment-plans';

export const paymentPlansApi = createApi({
  reducerPath: 'paymentPlansApi',
  baseQuery,
  tagTypes: ['PaymentPlans'],
  endpoints: builder => ({
    getPaymentPlans: builder.query<GetPaymentPlansResponse, GetAll<PaymentPlanType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}?${queryString}`,
        };
      },
      providesTags: ['PaymentPlans'],
    }),
    getPaymentPlansCount: builder.query<number, GetAll<PaymentPlanType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}/count?${queryString}`,
        };
      },
      providesTags: ['PaymentPlans'],
    }),
    getPaymentPlanById: builder.query<PaymentPlanType, string>({
      query: id => `${url}/${id}`,
      providesTags: (_, __, id) => [{ type: 'PaymentPlans', id }],
    }),
    createPaymentPlan: builder.mutation<PaymentPlanType, CreatePaymentPlanRequest>({
      query: body => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PaymentPlans'],
    }),
    updatePaymentPlan: builder.mutation<PaymentPlanType, UpdatePaymentPlanMutationRequest>({
      query: ({ id, body }) => {
        return {
          url: `${url}/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'PaymentPlans', id }, 'PaymentPlans'],
    }),
    deletePaymentPlan: builder.mutation<void, string>({
      query: id => ({
        url: `${url}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PaymentPlans'],
    }),
  }),
});

export const {
  useGetPaymentPlansQuery,
  useGetPaymentPlansCountQuery,
  useGetPaymentPlanByIdQuery,
  useCreatePaymentPlanMutation,
  useUpdatePaymentPlanMutation,
  useDeletePaymentPlanMutation,
} = paymentPlansApi;
