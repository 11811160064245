import {
  ActionsExtensionType,
  ActivityLogDocumentType,
  BroadcastDocumentType,
  ClientType,
  CounterPartyGetAmountGraphPoint,
  CustomerContext,
  CustomerContextGetForRoutedResponse,
  CustomerContextGetResponse,
  DashboardCountResponseType,
  DashboardResponseType,
  DashboardTeamOverviewResponseType,
  EntityChangePayload,
  EntityChangeType,
  GetCustomerByIdResponseType,
  GetEmailAttachmentResponseType,
  InvoicePageResponseType,
  InvoiceUpdateResponseType,
  MessageDeliveryStatusType,
  MessageGetByCustomerIdResponseType,
  MessageGetByCustomerIdsResponseType,
  MessageReceivedType,
  MessageRetryResponseType,
  MessageSendResponseType,
  MessageSendWhatsAppTemplateResponseType,
  MessageSentStatusUpdateType,
  MessageType,
  NoteCreateResponseType,
  NotificationReceivedType,
  PaymentPlanResponseType,
  QuickSightReportEmbedUrlResponseType,
  RenderedTransactionType,
  SQSErrorMessagesGetAllResponseType,
  SQSErrorMessagesProcessResponseType,
  SendEmailResponseType,
  TEAM_OVERVIEW_GRAPH_TYPE,
  TaskUpdateAssignResponseType,
  TaskUpdateResponseType,
  TeamOverviewAgentCollectionProfileResponseType,
  TeamOverviewAgentStatisticsResponseType,
  TranslationResponseType,
  UserType,
  WebSocketMessageType,
  WhatsAppTemplateGetAllResponseType,
  getFullName,
} from '@finance-ops/types';
import { useAuth } from 'apps/webapp/src/hooks/useAuth';
import { upsertUser } from 'apps/webapp/src/store/users';
import { addMessageHandler, sendMessage } from 'apps/webapp/src/websocket/WebSocketClient';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useTaskStateAndAssignedKeyNames } from '../hooks/useTaskState';
import { notify } from '../notification/Notifications';
import { AppDispatch } from '../store';
import { upsertManyActions, upsertOneAction } from '../store/action';
import { customerContextApi } from '../store/api/customercontextApi';
import { upsertOneClient } from '../store/client';
import { upsertOneCounterParty } from '../store/counterparty';
import {
  setAmountGraphData,
  updateOneCustomerContext as updateOneCustomerContextCustomerPage,
  upsertOneCustomerContext as upsertOneCustomerContextCustomerPage,
} from '../store/customerPageCContext';
import {
  CHAT_TAB,
  setAssignedAgentId,
  setChatTab,
  setCustomerSearchQuery,
  setSelectedCustomerContextId,
  setSelectedCustomerId,
  setTaskStateFilter,
  upsertOneCustomerContext,
} from '../store/customercontext';
import {
  setCollections,
  setCustomersTypeCount,
  setDashboardData,
  setLiveGraph,
  setQuickSightEmbedUrl,
} from '../store/dashboard';
import { upsertManyEmails } from '../store/email';
import { setHistoryLogs } from '../store/historyLog';
import { setInvoiceList, setInvoiceOverviewData, setTotalInvoices } from '../store/invoicePage';
import { setTranslations } from '../store/language';
import { upsertManyMessages, upsertOneMessage } from '../store/message';
import { upsertOneNotification } from '../store/notification';
import { setPaymentPlan, upsertOnePaymentPlan } from '../store/paymentPlans';
import { setSqsMessages, setSqsMessagesTotalCount, upsertOneSqsMessage } from '../store/sqsMessage';
import {
  setAgentCollectionsProfile,
  setAgentStatistics,
  setAgingSummary,
  setCollectionsGraph,
  setMessagesGraph,
  setP2PGraph,
  setSelectedAgentInformation,
} from '../store/teamOverview';
import { setTemplates } from '../store/template';
import { upsertTransactions } from '../store/transactions';
import { handleBroadcastDocument } from './broadcast-handler';
import { handleEntityChange } from './entity-change-handler';

const isBelongToClient = (user: UserType | null, clientId: string) => {
  if (!user) {
    return false;
  }
  return user.customerId === clientId;
};

const isInternalUser = (user: UserType | null) => {
  if (!user) {
    return false;
  }
  return user?.role?.isInternal;
};

const shouldProcessMessage = (user: UserType | null, clientId: string) =>
  isInternalUser(user) || isBelongToClient(user, clientId);

function WebsocketMessageHandlers() {
  const dispatch = useDispatch<AppDispatch>();
  const { user, setUser } = useAuth();

  const { taskStateKeyName } = useTaskStateAndAssignedKeyNames();

  useEffect(() => {
    addMessageHandler(WebSocketMessageType.CUSTOMER_CONTEXT_UPDATE, (payload: CustomerContext) => {
      if (payload?.task && shouldProcessMessage(user, payload.task.clientId)) {
        dispatch(upsertOneCustomerContext(payload));
        dispatch(updateOneCustomerContextCustomerPage(payload));
      }
    });

    addMessageHandler(WebSocketMessageType.CUSTOMER_CONTEXT_CLONE, (payload: CustomerContextGetResponse) => {
      // dispatch(upsertOneCustomerContext(payload.customerContext));
    });

    addMessageHandler(WebSocketMessageType.HISTORY_LOGS_GET_CUSTOMER_ID, (payload: ActivityLogDocumentType[]) => {
      dispatch(setHistoryLogs(payload));
    });

    addMessageHandler(WebSocketMessageType.CUSTOMER_CONTEXT_GET, (payload: CustomerContextGetResponse) => {
      if (payload?.customerContext?.task && shouldProcessMessage(user, payload.customerContext.task.clientId)) {
        dispatch(upsertOneCustomerContext(payload.customerContext));
        dispatch(upsertOneCustomerContextCustomerPage(payload.customerContext));
      }
    });

    addMessageHandler(
      WebSocketMessageType.CUSTOMER_CONTEXT_GET_FOR_ROUTED,
      (payload: CustomerContextGetForRoutedResponse) => {
        dispatch(upsertOneCustomerContext(payload.customerContext));
        dispatch(setSelectedCustomerContextId(payload.customerContext.task.id));
        const customerContextForThisCounterParty = CustomerContext.create(payload.customerContext);
        dispatch(setSelectedCustomerId(customerContextForThisCounterParty.getPrimaryCustomer()?._id.toString()));
        dispatch(setAssignedAgentId(undefined));
        dispatch(setChatTab(CHAT_TAB.ALL));
        dispatch(setTaskStateFilter(payload.customerContext.task[taskStateKeyName]));
        dispatch(setCustomerSearchQuery(getFullName(customerContextForThisCounterParty.getPrimaryCustomer())));
      },
    );

    addMessageHandler(WebSocketMessageType.CUSTOMER_UPDATE_LANGUAGE, (payload: CustomerContextGetResponse) => {
      if (payload?.customerContext?.task && shouldProcessMessage(user, payload.customerContext.task.clientId)) {
        dispatch(upsertOneCustomerContext(payload.customerContext));
        dispatch(upsertOneCustomerContextCustomerPage(payload.customerContext));
      }
      //TODO: @ANIKET to re-implement
      // dispatch(checkFilterAndUpsertCustomerContext(payload.customerContext));
    });

    addMessageHandler(WebSocketMessageType.CUSTOMER_UPDATE_CONTACT_STATUS, (payload: CustomerContextGetResponse) => {
      if (payload?.customerContext?.task && shouldProcessMessage(user, payload.customerContext.task.clientId)) {
        dispatch(upsertOneCustomerContext(payload.customerContext));
        dispatch(upsertOneCustomerContextCustomerPage(payload.customerContext));
      }
    });

    addMessageHandler(WebSocketMessageType.CUSTOMER_UPDATE_DND, (payload: CustomerContextGetResponse) => {
      if (payload?.customerContext?.task && shouldProcessMessage(user, payload.customerContext.task.clientId)) {
        dispatch(upsertOneCustomerContext(payload.customerContext));
        dispatch(upsertOneCustomerContextCustomerPage(payload.customerContext));
      }
    });

    addMessageHandler(WebSocketMessageType.TASK_UPDATE_MULTI, (payload: TaskUpdateAssignResponseType) => {
      if (payload?.customerContext?.task && shouldProcessMessage(user, payload.customerContext.task.clientId)) {
        dispatch(upsertOneCustomerContextCustomerPage(payload.customerContext));
        dispatch(upsertOneCustomerContext(payload.customerContext));
        dispatch(customerContextApi.util.invalidateTags(['CustomerContextCountGroupedByTaskState']));
        //TODO: @ANIKET to re-implement
        // dispatch(checkFilterAndUpsertCustomerContext(payload.customerContext));
      }
    });

    addMessageHandler(WebSocketMessageType.TASK_UPDATE, (payload: TaskUpdateResponseType) => {
      if (payload?.customerContext && shouldProcessMessage(user, payload.customerContext.task.clientId)) {
        dispatch(upsertOneCustomerContext(payload.customerContext));
        dispatch(upsertOneCustomerContextCustomerPage(payload.customerContext));
        dispatch(customerContextApi.util.invalidateTags(['CustomerContextCountGroupedByTaskState']));
        //TODO: @ANIKET to re-implement
        // dispatch(checkFilterAndUpsertCustomerContext(payload.customerContext));
      }
    });

    addMessageHandler(WebSocketMessageType.MESSAGE_RECEIVED, (payload: MessageReceivedType) => {
      if (
        payload?.customerContext?.task?.clientId &&
        shouldProcessMessage(user, payload.customerContext.task.clientId)
      ) {
        if (payload) {
          dispatch(upsertOneCustomerContext(payload.customerContext));
          //TODO: @ANIKET to re-implement
          // dispatch(checkFilterAndUpsertCustomerContext(payload.customerContext));
        }
        if (payload?.message) dispatch(upsertOneMessage(payload.message));
      }
    });

    addMessageHandler(WebSocketMessageType.MESSAGE_SEND, (payload: MessageSendResponseType) => {
      if (
        payload?.customerContext?.task?.clientId &&
        shouldProcessMessage(user, payload.customerContext.task.clientId)
      ) {
        dispatch(upsertOneMessage(payload.message));
        dispatch(upsertOneCustomerContext(payload.customerContext));
      }
    });

    addMessageHandler(
      WebSocketMessageType.MESSAGE_SEND_PLAYGROUND,
      (payload: MessageSendResponseType & { error: boolean }) => {
        if (payload.error) {
          notify({
            msg: {
              content: "Couldn't generate AI message. Please try again.",
            },
            type: 'error',
          });
        } else {
          dispatch(upsertOneMessage(payload.message));
        }
      },
    );
    addMessageHandler(WebSocketMessageType.MESSAGE_DELIVERY_STATUS, (payload: MessageDeliveryStatusType) => {
      dispatch(upsertOneMessage(payload.message));
    });
    addMessageHandler(WebSocketMessageType.MESSAGE_SENT_STATUS, (payload: MessageSentStatusUpdateType) => {
      if (
        payload?.customerContext?.task?.clientId &&
        shouldProcessMessage(user, payload.customerContext.task.clientId)
      ) {
        dispatch(upsertOneCustomerContext(payload.customerContext));
        dispatch(upsertOneMessage(payload.message));
        //TODO: @ANIKET to re-implement
        // dispatch(checkFilterAndUpsertCustomerContext(payload.customerContext));
      }
    });
    addMessageHandler(WebSocketMessageType.MESSAGE_SEND_RETRY, (payload: MessageRetryResponseType) => {
      if (
        payload?.customerContext?.task?.clientId &&
        shouldProcessMessage(user, payload.customerContext.task.clientId)
      ) {
        dispatch(upsertOneCustomerContext(payload.customerContext));
        dispatch(upsertOneMessage(payload.message));
        //TODO: @ANIKET to re-implement
        // dispatch(checkFilterAndUpsertCustomerContext(payload.customerContext));
      }
    });
    addMessageHandler(
      WebSocketMessageType.MESSAGE_SEND_WHATSAPP_TEMPLATE,
      (payload: MessageSendWhatsAppTemplateResponseType) => {
        if (payload?.customerContext?.task && shouldProcessMessage(user, payload.customerContext.task.clientId)) {
          dispatch(upsertOneCustomerContext(payload.customerContext));
          dispatch(upsertOneMessage(payload.message));
          //TODO: @ANIKET to re-implement
          // dispatch(checkFilterAndUpsertCustomerContext(payload.customerContext));
        }
      },
    );
    addMessageHandler(
      WebSocketMessageType.MESSAGE_GET_BY_CUSTOMER_ID,
      (payload: MessageGetByCustomerIdResponseType) => {
        if (payload?.messages) dispatch(upsertManyMessages(payload.messages));
      },
    );
    addMessageHandler(
      WebSocketMessageType.MESSAGE_GET_BY_CUSTOMER_IDS,
      (payload: MessageGetByCustomerIdsResponseType) => {
        if (payload?.messages) dispatch(upsertManyMessages(payload.messages));
      },
    );
    addMessageHandler(WebSocketMessageType.CUSTOMER_GET_BY_ID, (payload: GetCustomerByIdResponseType) => {
      if (payload?.customer) dispatch(upsertOneCounterParty(payload.customer));
    });

    addMessageHandler(WebSocketMessageType.MANAGE_GENERAL_SETTINGS, (payload: ClientType) => {
      if (payload) dispatch(upsertOneClient(payload));
    });
    addMessageHandler(WebSocketMessageType.GET_DASHBOARD_SUMMARY, (payload: DashboardResponseType) => {
      dispatch(setDashboardData(payload));
    });
    addMessageHandler(WebSocketMessageType.NOTE_CREATE_CLIENT, (payload: NoteCreateResponseType) => {
      if (
        payload?.customerContext?.task?.clientId &&
        shouldProcessMessage(user, payload.customerContext?.task.clientId)
      ) {
        if (payload?.message) dispatch(upsertOneMessage(payload.message));
        if (payload?.customerContext) {
          dispatch(upsertOneCustomerContext(payload.customerContext));
          //TODO: @ANIKET to re-implement
          // dispatch(checkFilterAndUpsertCustomerContext(payload.customerContext as CustomerContext));
        }
      }
    });
    addMessageHandler(WebSocketMessageType.NOTE_CREATE_INTERNAL, (payload: NoteCreateResponseType) => {
      if (
        payload?.customerContext?.task?.clientId &&
        shouldProcessMessage(user, payload.customerContext?.task.clientId)
      ) {
        if (payload?.message) dispatch(upsertOneMessage(payload.message));
        if (payload?.customerContext) {
          dispatch(upsertOneCustomerContext(payload.customerContext));
          //TODO: @ANIKET to re-implement
          // dispatch(checkFilterAndUpsertCustomerContext(payload.customerContext as CustomerContext));
        }
      }
    });
    addMessageHandler(WebSocketMessageType.NOTE_CREATE_INFO, (payload: NoteCreateResponseType) => {
      if (
        payload?.customerContext?.task?.clientId &&
        shouldProcessMessage(user, payload.customerContext?.task.clientId)
      ) {
        if (payload?.message) dispatch(upsertOneMessage(payload.message));
        if (payload?.customerContext) {
          dispatch(upsertOneCustomerContext(payload.customerContext));
          //TODO: @ANIKET to re-implement
          // dispatch(checkFilterAndUpsertCustomerContext(payload.customerContext as CustomerContext));
        }
      }
    });
    addMessageHandler(WebSocketMessageType.INVOICE_UPDATE, (payload: InvoiceUpdateResponseType) => {
      if (payload?.customerContext && shouldProcessMessage(user, payload.customerContext.task.clientId)) {
        dispatch(upsertOneCustomerContext(payload.customerContext));
        //TODO: @ANIKET to re-implement
        // dispatch(checkFilterAndUpsertCustomerContext(payload.customerContext));
      }
    });

    addMessageHandler(WebSocketMessageType.NOTIFICATION_RECEIVED, (payload: NotificationReceivedType) => {
      if (payload?.notification) {
        const content = payload.notification.templates?.WEBAPP ?? payload.notification.content;
        dispatch(upsertOneNotification(payload.notification));
        notify({
          id: payload.notification.id.toString(),
          msg: { content },
          type: 'info',
        });
      }
    });

    addMessageHandler(WebSocketMessageType.TRANSLATION_REQUEST, (payload: TranslationResponseType) => {
      dispatch(setTranslations(payload));
    });
    addMessageHandler(WebSocketMessageType.WHATSAPP_TEMPLATE_GET_ALL, (payload: WhatsAppTemplateGetAllResponseType) => {
      dispatch(setTemplates(payload.templates));
    });
    addMessageHandler(WebSocketMessageType.SQS_ERROR_GET_ALL, (response: SQSErrorMessagesGetAllResponseType) => {
      dispatch(setSqsMessagesTotalCount(response.totalCount));
      dispatch(setSqsMessages(response.sqsMessages));
    });
    addMessageHandler(WebSocketMessageType.SQS_ERROR_DISCARD, (response: SQSErrorMessagesProcessResponseType) => {
      dispatch(upsertOneSqsMessage(response));
    });
    addMessageHandler(WebSocketMessageType.SQS_ERROR_PROCESS, (response: SQSErrorMessagesProcessResponseType) => {
      dispatch(upsertOneSqsMessage(response));
    });

    addMessageHandler(WebSocketMessageType.GET_ACTIONS_BY_CUSTOMER, (payload: ActionsExtensionType[]) => {
      const filteredPayload = payload.filter(action => {
        return action?.clientId && shouldProcessMessage(user, action?.clientId);
      });
      dispatch(upsertManyActions(filteredPayload));
    });
    addMessageHandler(WebSocketMessageType.GET_ACTIONS_BY_CUSTOMER_IDS, (payload: ActionsExtensionType[]) => {
      const filteredPayload = payload.filter(action => {
        return action?.clientId && shouldProcessMessage(user, action?.clientId);
      });
      dispatch(upsertManyActions(filteredPayload));
    });

    addMessageHandler(WebSocketMessageType.UPDATE_CUSTOMER, (payload: CustomerContextGetResponse) => {
      if (payload?.customerContext?.task && shouldProcessMessage(user, payload.customerContext.task.clientId)) {
        dispatch(upsertOneCustomerContext(payload.customerContext));
        dispatch(upsertOneCustomerContextCustomerPage(payload.customerContext));
      }
    });
    addMessageHandler(WebSocketMessageType.RESET_DEMO_CUSTOMER, (payload: CustomerContextGetResponse) => {
      dispatch(upsertOneCustomerContext(payload.customerContext));
    });
    addMessageHandler(
      WebSocketMessageType.GET_QUICKSIGHT_EMBED_URL,
      (payload: QuickSightReportEmbedUrlResponseType) => {
        if (payload.Status === 200) {
          dispatch(setQuickSightEmbedUrl(payload.EmbedUrl));
        } else {
          console.error('QuickSight Embed URL Error', payload);
        }
      },
    );

    addMessageHandler(WebSocketMessageType.MESSAGE_UPDATE, (payload: MessageType) => {
      dispatch(upsertOneMessage(payload));
    });
    addMessageHandler(
      WebSocketMessageType.GET_COUNTERPARTY_AMOUNT_GRAPH_DATA,
      (payload: CounterPartyGetAmountGraphPoint[]) => {
        dispatch(setAmountGraphData(payload));
      },
    );
    addMessageHandler(WebSocketMessageType.GET_DASHBOARD_TYPE_COUNTS, (payload: DashboardCountResponseType) => {
      dispatch(setCustomersTypeCount(payload));
    });
    addMessageHandler(
      WebSocketMessageType.DASHBOARD_GET_TEAM_OVERVIEW_GRAPH,
      (payload: DashboardTeamOverviewResponseType) => {
        if (!payload.payload) return;
        switch (payload.type) {
          case TEAM_OVERVIEW_GRAPH_TYPE.AGING_SUMMARY:
            dispatch(setAgingSummary(payload.payload));
            break;
          case TEAM_OVERVIEW_GRAPH_TYPE.P2P_GRAPH:
            dispatch(setP2PGraph(payload.payload));
            break;
          case TEAM_OVERVIEW_GRAPH_TYPE.COLLECTIONS_GRAPH:
            dispatch(setCollectionsGraph(payload.payload));
            break;
          case TEAM_OVERVIEW_GRAPH_TYPE.MESSAGES_GRAPH:
            dispatch(setMessagesGraph(payload.payload));
            break;
          default:
            console.log('DEFAULT', payload.type);
            break;
        }
      },
    );
    addMessageHandler(WebSocketMessageType.GET_SPECIFIC_USER, (payload: UserType) => {
      dispatch(setSelectedAgentInformation(payload));
    });
    addMessageHandler(WebSocketMessageType.GET_INVOICES_FOR_INVOICE_PAGE, (payload: InvoicePageResponseType) => {
      dispatch(setInvoiceList(payload.invoicePageData));
      const total = Object.values(payload.countDataObject).reduce((acc, current) => acc + current, 0);
      dispatch(setTotalInvoices(total));
      dispatch(setInvoiceOverviewData(payload.countDataObject));
    });
    addMessageHandler(
      WebSocketMessageType.TEAM_OVERVIEW_GET_AGENT_COLLECTION_PROFILE,
      (payload: TeamOverviewAgentCollectionProfileResponseType) => {
        dispatch(setAgentCollectionsProfile(payload));
      },
    );
    addMessageHandler(
      WebSocketMessageType.TEAM_OVERVIEW_GET_AGENT_STATISTICS,
      (payload: TeamOverviewAgentStatisticsResponseType) => {
        dispatch(setAgentStatistics(payload));
      },
    );
    addMessageHandler(WebSocketMessageType.PAYMENT_PLAN, (payload: PaymentPlanResponseType) => {
      switch (payload.type) {
        case 'CREATE':
          dispatch(upsertOnePaymentPlan(payload.payload));
          // Getting p2ps

          sendMessage(WebSocketMessageType.GET_ACTIONS_BY_CUSTOMER, payload.payload.customerId);
          break;
        case 'GET_BY_CUSTOMER_ID':
          dispatch(setPaymentPlan(payload.payload ?? []));
          break;
        case 'UPDATE':
          dispatch(upsertOnePaymentPlan(payload.payload));
          break;
      }
    });
    addMessageHandler(WebSocketMessageType.SERVER_ERROR, (payload: any) => {
      console.error(payload);
      let errorMessage = payload?.error?.message;
      if (payload?.error?.error) {
        errorMessage = payload?.error?.error;
      }
      if (!errorMessage && payload?.error?.messages) {
        errorMessage = payload?.error?.messages[0];
      }
      if (!errorMessage) {
        errorMessage = 'Something went wrong';
      }

      // if External User Always show Something went wrong
      if (!isInternalUser) {
        errorMessage = 'Something went wrong';
      }

      toast.error(errorMessage, {
        duration: 2000,
      });
    });
    addMessageHandler(WebSocketMessageType.MANAGE_USER, (payload: any) => {
      dispatch(upsertUser(payload));
    });
    addMessageHandler(WebSocketMessageType.MANAGE_PERSONAL_SETTINGS, (payload: { user: UserType }) => {
      if (payload.user) {
        dispatch(upsertUser(payload.user));
        setUser(payload.user);
      }
    });

    addMessageHandler(WebSocketMessageType.GET_EMAIL_BY_CUSTOMER_IDS, (payload: any) => {
      dispatch(upsertManyEmails(payload.emails));
    });
    addMessageHandler(WebSocketMessageType.SEND_EMAIL, (payload: SendEmailResponseType) => {
      notify({
        msg: { content: 'Sending email, please wait...' },
        type: 'info',
        timeout: 20000,
        playAudio: false,
      });
    });
    addMessageHandler(WebSocketMessageType.GET_EMAIL_ATTACHMENT, (payload: GetEmailAttachmentResponseType) => {
      const a = document.createElement('a'); //Create <a>
      a.href = 'data:application/octet-stream;base64,' + payload.data; //Image Base64 Goes here
      a.download = payload.filename as string; //File name Here
      a.click(); //Downloaded file
    });

    addMessageHandler(WebSocketMessageType.GET_DASHBOARD_AGG, (payload: any) => {
      notify({ msg: { content: 'Dashboard aggregations job completed' } });
    });
    addMessageHandler(WebSocketMessageType.GET_DASHBOARD_GRAPH, (payload: any) => {
      dispatch(setLiveGraph(payload));
    });
    addMessageHandler(WebSocketMessageType.GET_DASHBOARD_SYNC, (payload: any) => {
      dispatch(setCollections({ loading: false, data: payload }));
    });
    addMessageHandler(WebSocketMessageType.TRANSACTION_ATTACHMENT_UPLOAD, (payload: RenderedTransactionType) => {
      notify({
        msg: { content: 'Transaction attachment uploaded successfully' },
        type: 'success',
        timeout: 800,
        playAudio: false,
      });
      dispatch(upsertTransactions([payload]));
    });
    addMessageHandler(WebSocketMessageType.ENTITY_CHANGE, (payload: EntityChangePayload<any>) => {
      // Handle cache update first
      handleEntityChange(payload);

      // FIXME: Remove this once we have move actions to RTK Query cache and remove redux
      try {
        if (payload.entity === 'actions' && payload.type !== EntityChangeType.DELETED) {
          dispatch(upsertOneAction(payload.data as ActionsExtensionType));
        }
      } catch (error) {
        console.error('Error updating action:', error);
      }
    });

    addMessageHandler(WebSocketMessageType.BROADCAST_DOCUMENT, (payload: BroadcastDocumentType) => {
      handleBroadcastDocument(payload);
    });
  }, [dispatch, user]);
  return <></>;
}

export default WebsocketMessageHandlers;
