import {
  ClientType,
  CreateClientRequest,
  GetClientsResponse,
  UpdateClientMutationRequest,
} from '@finance-ops/collections/client-types';
import { GetAll } from '@finance-ops/repository';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { stringify } from 'qs';
import { clientSlice } from '../client';
import { ClientType as LegacyClientType } from '@finance-ops/types';

export const apiController = new AbortController();

const url = '/v2/clients';

export const clientsApi = createApi({
  reducerPath: 'clientsApi',
  baseQuery,
  tagTypes: ['Clients'],
  endpoints: builder => ({
    getClients: builder.query<GetClientsResponse, GetAll<ClientType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}?${queryString}`,
        };
      },
      providesTags: ['Clients'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled;
        if (result.data) {
          const clientsWithId = result.data.map(client => ({
            ...client,
            id: client._id,
          }));
          dispatch(clientSlice.actions.upsertManyClients(clientsWithId as unknown as LegacyClientType[]));
        }
      },
    }),
    // clientAssignUsers: builder.mutation<ClientType, { clientId: string; userIds: string[] }>({
    //   query: ({ clientId, userIds }) => ({
    //     url: `/clients/${clientId}/assignUsers`,
    //     method: 'PATCH',
    //     body: { assignedUserIds: userIds },
    //   }),
    //   invalidatesTags: (result, error, { clientId }) => [{ type: 'clients', id: clientId }],
    //   async onQueryStarted({ clientId, userIds }, { dispatch, queryFulfilled }) {
    //     const result = await queryFulfilled;
    //     dispatch(clientSlice.actions.upsertOneClient(result.data));
    //   },
    // }),
    getClientsCount: builder.query<number, GetAll<ClientType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}/count?${queryString}`,
        };
      },
      providesTags: ['Clients'],
    }),
    getClientById: builder.query<ClientType, string>({
      query: id => `${url}/${id}`,
      providesTags: (_, __, id) => [{ type: 'Clients', id }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled;
        if (result.data) {
          const clientWithId = {
            ...result.data,
            id: result.data._id,
          };
          dispatch(clientSlice.actions.upsertOneClient(clientWithId as unknown as LegacyClientType));
        }
      },
    }),
    createClient: builder.mutation<ClientType, CreateClientRequest>({
      query: body => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Clients'],
    }),
    updateClient: builder.mutation<ClientType, UpdateClientMutationRequest>({
      query: ({ id, body }) => {
        return {
          url: `${url}/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Clients', id }, 'Clients'],
    }),
    deleteClient: builder.mutation<void, string>({
      query: id => ({
        url: `${url}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Clients'],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useLazyGetClientsQuery,
  useGetClientsCountQuery,
  useGetClientByIdQuery,
  useLazyGetClientByIdQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientsApi;
