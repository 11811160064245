import {
  CustomerContextType,
  CreateCustomerContextRequest,
  GetCustomerContextsResponse,
  UpdateCustomerContextMutationRequest,
  CustomerContextCount,
} from '@finance-ops/collections/customer-context-types';
import { GetAll } from '@finance-ops/repository';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { stringify } from 'qs';

export const apiController = new AbortController();

const url = '/v2/customerContexts';

export const customerContextsApi = createApi({
  reducerPath: 'customerContextsApi',
  baseQuery,
  tagTypes: ['CustomerContexts'],
  endpoints: builder => ({
    getCustomerContexts: builder.query<GetCustomerContextsResponse, GetAll<CustomerContextType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}?${queryString}`,
        };
      },
      providesTags: ['CustomerContexts'],
    }),
    //update any here
    getCustomerContextsCount: builder.query<CustomerContextCount, GetAll<CustomerContextType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}/counts?${queryString}`,
        };
      },
      providesTags: ['CustomerContexts'],
    }),
    getCustomerContextsTotalAmountCollected: builder.query<number, GetAll<CustomerContextType>>({
      query: params => {
        const transformedParams = {
          ...params,
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}/totalAmountCollected?${queryString}`,
        };
      },
      providesTags: ['CustomerContexts'],
    }),
    getCustomerContextById: builder.query<CustomerContextType, string>({
      query: id => `${url}/${id}`,
      providesTags: (_, __, id) => [{ type: 'CustomerContexts', id }],
    }),
    createCustomerContext: builder.mutation<CustomerContextType, CreateCustomerContextRequest>({
      query: body => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CustomerContexts'],
    }),
    updateCustomerContext: builder.mutation<CustomerContextType, UpdateCustomerContextMutationRequest>({
      query: ({ id, body }) => {
        return {
          url: `${url}/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'CustomerContexts', id }, 'CustomerContexts'],
    }),
    deleteCustomerContext: builder.mutation<void, string>({
      query: id => ({
        url: `${url}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CustomerContexts'],
    }),
  }),
});

export const {
  useGetCustomerContextsQuery,
  useGetCustomerContextsCountQuery,
  useGetCustomerContextByIdQuery,
  useCreateCustomerContextMutation,
  useUpdateCustomerContextMutation,
  useDeleteCustomerContextMutation,
  useGetCustomerContextsTotalAmountCollectedQuery,
} = customerContextsApi;
