const RespossesonIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='14' viewBox='0 0 18 14' fill='none'>
      <path
        d='M8.125 4.51263H16.8333'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 6.09596L3.22191 3.59631C3.82285 2.92026 4.6842 2.53346 5.58871 2.53346H5.75'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 12.8254L5.35417 12.8251L8.52083 10.4504C8.52083 10.4504 9.16137 10.0169 10.1042 9.2627C12.0833 7.67945 10.1042 5.1725 8.125 6.49155C6.51324 7.56569 4.95833 8.4712 4.95833 8.4712'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.75 8.07513V2.9293C5.75 2.05484 6.45888 1.34596 7.33333 1.34596H15.25C16.1245 1.34596 16.8333 2.05484 16.8333 2.9293V7.6793C16.8333 8.55377 16.1245 9.26263 15.25 9.26263H10.1042'
        stroke='#3E3E3E'
        strokeWidth='1.5'
      />
    </svg>
  );
};

export default RespossesonIcon;
