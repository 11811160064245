import { z } from 'zod';

export const csvImportSchema = z.object({
  customerId: z.string().optional(),
  customerName: z.string().min(1, 'Customer name is required'),
  email: z.string().optional(),
  phone: z.string().optional(),
  address: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zipCode: z.string().optional(),
  invoiceId: z.string().optional(),
  invoiceDate: z.string().refine(val => !isNaN(Date.parse(val)), { message: 'Invalid date format' }),
  dueDate: z.string().refine(val => !isNaN(Date.parse(val)), { message: 'Invalid date format' }),
  dueAmount: z
    .string()
    .refine(val => !isNaN(parseFloat(val)) && parseFloat(val) >= 0, { message: 'Amount must be a positive number' }),
  description: z.string().optional(),
});

export type CsvImportSchema = z.infer<typeof csvImportSchema>;
