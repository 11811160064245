import {
  TaskType,
  CreateTaskRequest,
  GetTasksResponse,
  UpdateTaskMutationRequest,
} from '@finance-ops/collections/task-types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { stringify } from 'qs';
import { GetAll } from '@finance-ops/repository';

export const apiController = new AbortController();

const url = '/v2/tasks';

export const tasksApi = createApi({
  reducerPath: 'tasksApi',
  baseQuery,
  tagTypes: ['Tasks'],
  endpoints: builder => ({
    getTasks: builder.query<GetTasksResponse, GetAll<TaskType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}?${queryString}`,
        };
      },
      providesTags: ['Tasks'],
    }),
    getTasksCount: builder.query<number, GetAll<TaskType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}/count?${queryString}`,
        };
      },
      providesTags: ['Tasks'],
    }),
    getTaskById: builder.query<TaskType, string>({
      query: id => `${url}/${id}`,
      providesTags: (_, __, id) => [{ type: 'Tasks', id }],
    }),
    createTask: builder.mutation<TaskType, CreateTaskRequest>({
      query: body => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tasks'],
    }),
    updateTask: builder.mutation<TaskType, UpdateTaskMutationRequest>({
      query: ({ id, body }) => {
        return {
          url: `${url}/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Tasks', id }, 'Tasks'],
    }),
    deleteTask: builder.mutation<void, string>({
      query: id => ({
        url: `${url}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tasks'],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetTasksCountQuery,
  useGetTaskByIdQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
} = tasksApi;
