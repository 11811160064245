const CustomerUntouched = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.02875 2.7794C7.02875 3.73072 6.25756 4.50191 5.30624 4.50191C4.35493 4.50191 3.58374 3.73072 3.58374 2.7794C3.58374 1.82809 4.35493 1.0569 5.30624 1.0569C6.25756 1.0569 7.02875 1.82809 7.02875 2.7794Z'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.61252 10.1C9.61252 11.7648 7.68418 13.1144 5.30626 13.1144C2.92834 13.1144 1 11.7648 1 10.1C1 8.43524 2.92834 7.08566 5.30626 7.08566C7.68418 7.08566 9.61252 8.43524 9.61252 10.1Z'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.8653 0.52657C11.5724 0.233677 11.0975 0.233677 10.8046 0.52657C10.5117 0.819463 10.5117 1.29434 10.8046 1.58723L11.8653 0.52657ZM12.5271 3.30973C12.82 3.60263 13.2949 3.60263 13.5878 3.30973C13.8807 3.01684 13.8807 2.54197 13.5878 2.24907L12.5271 3.30973ZM13.5879 2.24907C13.295 1.95618 12.8201 1.95618 12.5272 2.24907C12.2343 2.54197 12.2343 3.01684 12.5272 3.30973L13.5879 2.24907ZM14.2497 5.03224C14.5426 5.32513 15.0175 5.32513 15.3104 5.03224C15.6033 4.73934 15.6033 4.26447 15.3104 3.97158L14.2497 5.03224ZM13.5879 3.30973C13.8807 3.01684 13.8807 2.54197 13.5879 2.24907C13.295 1.95618 12.8201 1.95618 12.5272 2.24907L13.5879 3.30973ZM10.8047 3.97158C10.5118 4.26447 10.5118 4.73934 10.8047 5.03224C11.0976 5.32513 11.5725 5.32513 11.8654 5.03224L10.8047 3.97158ZM12.5272 2.24907C12.2343 2.54197 12.2343 3.01684 12.5272 3.30973C12.8201 3.60263 13.295 3.60263 13.5879 3.30973L12.5272 2.24907ZM15.3104 1.58723C15.6033 1.29434 15.6033 0.819463 15.3104 0.52657C15.0175 0.233677 14.5426 0.233677 14.2497 0.52657L15.3104 1.58723ZM10.8046 1.58723L12.5271 3.30973L13.5878 2.24907L11.8653 0.52657L10.8046 1.58723ZM12.5272 3.30973L14.2497 5.03224L15.3104 3.97158L13.5879 2.24907L12.5272 3.30973ZM12.5272 2.24907L10.8047 3.97158L11.8654 5.03224L13.5879 3.30973L12.5272 2.24907ZM13.5879 3.30973L15.3104 1.58723L14.2497 0.52657L12.5272 2.24907L13.5879 3.30973Z'
        fill='#3E3E3E'
      />
    </svg>
  );
};

export default CustomerUntouched;
