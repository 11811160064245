import { Alert } from '@mui/material';
import useSelectedClientId from '../hooks/useSelectedClientId';
import { useGetClientByIdQuery } from '../store/api/clients.api';
import { useMemo } from 'react';

export const GlobalProcessingBar = () => {
  const clientId = useSelectedClientId();
  const { data: clientData, isLoading } = useGetClientByIdQuery(clientId ?? '', {
    skip: !clientId,
  });

  const shouldShowAlert = useMemo(() => {
    if (!clientId || isLoading || !clientData) return false;
    return clientData.isDataProcessing;
  }, [clientId, isLoading, clientData]);

  if (!shouldShowAlert) return null;

  return (
    <Alert severity='warning' variant='filled' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      Your data is being processed by our AI models. We will notify you once it's completed.
    </Alert>
  );
};
