import {
  CatType,
  CreateCatRequest,
  GetCatsQueryParams,
  GetCatsResponse,
  UpdateCatMutationRequest,
} from '@finance-ops/collections/cats-types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const apiController = new AbortController();

export const catsApi = createApi({
  reducerPath: 'catsApi',
  baseQuery,
  tagTypes: ['Cats'],
  endpoints: builder => ({
    getCats: builder.query<GetCatsResponse, GetCatsQueryParams>({
      query: params => {
        // Transform sort and order into the format expected by the backend
        const { sort, order, ...restParams } = params;
        const transformedParams = {
          ...restParams,
          ...(sort && order ? { [`sort[${sort}]`]: order } : {}),
        };

        return {
          url: '/cats',
          params: transformedParams,
        };
      },
      providesTags: ['Cats'],
    }),
    getCatById: builder.query<CatType, string>({
      query: id => `/cats/${id}`,
      providesTags: (_, __, id) => [{ type: 'Cats', id }],
    }),
    createCat: builder.mutation<CatType, CreateCatRequest>({
      query: body => ({
        url: '/cats',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Cats'],
    }),
    updateCat: builder.mutation<CatType, UpdateCatMutationRequest>({
      query: ({ id, body }) => {
        return {
          url: `/cats/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Cats', id }, 'Cats'],
    }),
    deleteCat: builder.mutation<void, string>({
      query: id => ({
        url: `/cats/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Cats'],
    }),
  }),
});

export const { useGetCatsQuery, useGetCatByIdQuery, useCreateCatMutation, useUpdateCatMutation, useDeleteCatMutation } =
  catsApi;
