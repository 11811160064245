const ActivitySuccess = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'>
      <rect x='0.677734' y='0.895401' width='11.5573' height='11.5573' rx='5.77864' fill='#238600' />
      <path
        d='M4.25464 6.67405L5.72262 8.0895L8.65825 5.25859'
        stroke='white'
        strokeWidth='1.07305'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ActivitySuccess;
