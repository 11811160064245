import { BroadcastDocumentType } from '@finance-ops/types';
import { store } from '../store';
import { actionsApi } from '../store/api/actionsApi';
import { clientsApi } from '../store/api/clients.api';
import { commentsApi } from '../store/api/commentsApi';
import { customerContextApi } from '../store/api/customercontextApi';

const apiMap = {
  actions: actionsApi,
  clients: clientsApi,
  comments: commentsApi,
  customerContext: customerContextApi,
} as const;

interface QueryState {
  endpointName: string;
  originalArgs: any;
}

interface EntityItem {
  id?: string;
  _id?: string;
}

export function handleBroadcastDocument(payload: BroadcastDocumentType) {
  const { collectionName, documentId, document } = payload;
  const api = apiMap[collectionName as keyof typeof apiMap];

  if (!api) {
    console.dir(`No API found for collection: ${collectionName}`);
    return;
  }

  const queries = store.getState()[api.reducerPath].queries;
  if (!queries) return;

  Object.entries(queries).forEach(([queryKey, query]) => {
    const typedQuery = query as QueryState;
    const endpoint = typedQuery.endpointName;

    if (!(endpoint in api.endpoints)) return;

    store.dispatch(
      (api.util.updateQueryData as any)(endpoint, typedQuery.originalArgs, (draft: any) => {
        // Handle paginated data
        if (draft?.data) {
          const items = draft.data;
          const index = items.findIndex((item: EntityItem) => item._id === documentId || item.id === documentId);

          if (index !== -1) {
            items[index] = document;
          }
        }
        // Handle array responses
        else if (Array.isArray(draft)) {
          const index = draft.findIndex(item => item._id === documentId || item.id === documentId);

          if (index !== -1) {
            draft[index] = document;
          }
        }
        // Handle single entity responses
        else if (draft?.id === documentId || draft?._id === documentId) {
          Object.assign(draft, document);
        }
      }),
    );
  });
}
