import { useGetClientsQuery, useGetClientByIdQuery } from '../store/api/clients.api';
import { useAuth } from './useAuth';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
interface UseClientsOptions {
  pageSize?: number;
  pageNumber?: number;
}

export function useClients(options: UseClientsOptions = {}) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const isInternalUser = user?.role?.isInternal;
  const customerId = user?.customerId;

  const {
    data: clients,
    isLoading: isLoadingClients,
    error: clientsError,
  } = useGetClientsQuery(
    {
      pageSize: options.pageSize || 100,
      pageNumber: options.pageNumber || 1,
    },
    {
      skip: !isInternalUser,
    },
  );

  const {
    data: client,
    isLoading: isLoadingClient,
    error: clientError,
  } = useGetClientByIdQuery(customerId || '', {
    skip: isInternalUser || !customerId,
  });

  const isLoading = isInternalUser ? isLoadingClients : isLoadingClient;
  const error = isInternalUser ? clientsError : clientError;

  // Memoize the data transformation to prevent unnecessary recalculations
  const data = useMemo(() => {
    if (isInternalUser) {
      return clients;
    }
    return client ? [client] : undefined;
  }, [isInternalUser, clients, client]);

  return {
    data,
    isLoading,
    error,
    isInternalUser,
  };
}
