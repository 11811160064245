import {
  GatewayTransactionType,
  CreateGatewayTransactionRequest,
  GetGatewayTransactionsResponse,
  UpdateGatewayTransactionMutationRequest,
} from '@finance-ops/collections/gateway-transaction-types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { GetAll } from '@finance-ops/repository';
import { stringify } from 'qs';

export const apiController = new AbortController();

const url = '/v2/gateway-transactions';

export const gatewayTransactionsApi = createApi({
  reducerPath: 'gatewayTransactionsApi',
  baseQuery,
  tagTypes: ['GatewayTransactions'],
  endpoints: builder => ({
    getGatewayTransactions: builder.query<GetGatewayTransactionsResponse, GetAll<GatewayTransactionType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}?${queryString}`,
        };
      },
      providesTags: ['GatewayTransactions'],
    }),
    getGatewayTransactionsCount: builder.query<number, GetAll<GatewayTransactionType>>({
      query: params => {
        const transformedParams = {
          ...params,
          pageSize: Number(params.pageSize),
          pageNumber: Number(params.pageNumber),
        };

        const queryString = stringify(transformedParams, {
          arrayFormat: 'brackets',
          encode: false,
          serializeDate: (date: Date) => date.toISOString(),
        });

        return {
          url: `${url}/count?${queryString}`,
        };
      },
      providesTags: ['GatewayTransactions'],
    }),
    getGatewayTransactionById: builder.query<GatewayTransactionType, string>({
      query: id => `${url}/${id}`,
      providesTags: (_, __, id) => [{ type: 'GatewayTransactions', id }],
    }),
    createGatewayTransaction: builder.mutation<GatewayTransactionType, CreateGatewayTransactionRequest>({
      query: body => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GatewayTransactions'],
    }),
    updateGatewayTransaction: builder.mutation<GatewayTransactionType, UpdateGatewayTransactionMutationRequest>({
      query: ({ id, body }) => {
        return {
          url: `${url}/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'GatewayTransactions', id }, 'GatewayTransactions'],
    }),
    deleteGatewayTransaction: builder.mutation<void, string>({
      query: id => ({
        url: `${url}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GatewayTransactions'],
    }),
  }),
});

export const {
  useGetGatewayTransactionsQuery,
  useGetGatewayTransactionsCountQuery,
  useGetGatewayTransactionByIdQuery,
  useCreateGatewayTransactionMutation,
  useUpdateGatewayTransactionMutation,
  useDeleteGatewayTransactionMutation,
} = gatewayTransactionsApi;
