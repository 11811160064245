const NewCustomers = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='37' height='37' viewBox='0 0 37 37' fill='none'>
      <rect
        x='0.82959'
        y='0.540222'
        width='35.3481'
        height='35.3481'
        rx='17.674'
        stroke='#A3A3A3'
        strokeOpacity='0.38'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.3323 14.6374C20.3323 15.6472 19.5136 16.4658 18.5038 16.4658C17.4939 16.4658 16.6753 15.6472 16.6753 14.6374C16.6753 13.6275 17.4939 12.8089 18.5038 12.8089C19.5136 12.8089 20.3323 13.6275 20.3323 14.6374Z'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.1606 21.1045C22.1606 22.4903 20.5231 23.6197 18.5037 23.6197C16.4842 23.6197 14.8467 22.4933 14.8467 21.1045C14.8467 19.7156 16.4842 18.59 18.5037 18.59C20.5231 18.59 22.1606 19.7156 22.1606 21.1045Z'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.2006 16.3142C24.2085 16.7596 23.9462 17.1655 23.5369 17.3414C23.1276 17.5173 22.6526 17.4282 22.3349 17.116C22.0171 16.8038 21.9196 16.3305 22.0883 15.9182C22.2569 15.5058 22.6581 15.2364 23.1036 15.2363C23.7041 15.2312 24.1951 15.7137 24.2006 16.3142V16.3142Z'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8066 16.3142C12.7988 16.7596 13.061 17.1655 13.4703 17.3414C13.8796 17.5173 14.3546 17.4282 14.6724 17.116C14.9901 16.8038 15.0876 16.3305 14.919 15.9182C14.7503 15.5058 14.3491 15.2364 13.9037 15.2363C13.3031 15.2312 12.8121 15.7137 12.8066 16.3142Z'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.6903 22.1544C23.2777 22.1175 22.9133 22.422 22.8764 22.8345C22.8394 23.2471 23.1439 23.6115 23.5565 23.6484L23.6903 22.1544ZM25.8176 21.1045L26.5631 21.1862C26.569 21.1318 26.569 21.0769 26.5631 21.0226L25.8176 21.1045ZM23.5564 18.5613C23.1439 18.5983 22.8394 18.9627 22.8764 19.3753C22.9133 19.7878 23.2777 20.0923 23.6903 20.0554L23.5564 18.5613ZM13.4508 23.6484C13.8633 23.6115 14.1678 23.2471 14.1309 22.8345C14.094 22.4219 13.7296 22.1174 13.317 22.1544L13.4508 23.6484ZM11.1897 21.1045L10.4442 21.0225C10.4382 21.0769 10.4382 21.1318 10.4442 21.1861L11.1897 21.1045ZM13.317 20.0553C13.7295 20.0923 14.0939 19.7878 14.1309 19.3752C14.1679 18.9627 13.8634 18.5983 13.4508 18.5613L13.317 20.0553ZM23.5565 23.6484C25.0619 23.7832 26.3985 22.6887 26.5631 21.1862L25.072 21.0228C24.9964 21.7133 24.3821 22.2164 23.6903 22.1544L23.5565 23.6484ZM26.5631 21.0226C26.3979 19.5205 25.0615 18.4265 23.5564 18.5613L23.6903 20.0554C24.382 19.9934 24.9962 20.4962 25.072 21.1865L26.5631 21.0226ZM13.317 22.1544C12.6251 22.2163 12.0109 21.7133 11.9352 21.0228L10.4442 21.1861C10.6088 22.6886 11.9453 23.7832 13.4508 23.6484L13.317 22.1544ZM11.9352 21.1864C12.0111 20.4961 12.6253 19.9934 13.317 20.0553L13.4508 18.5613C11.9457 18.4265 10.6093 19.5204 10.4442 21.0225L11.9352 21.1864Z'
        fill='#3E3E3E'
      />
    </svg>
  );
};

export default NewCustomers;
