import { PGliteWorker } from '@electric-sql/pglite/worker';
import { DB_NAME } from './config';
import { devtoolsEnabled } from './config';

let dbWorker: PGliteWorker | null = null;
let isInitializing = false;

export async function getWorker(): Promise<PGliteWorker> {
  if (!devtoolsEnabled) {
    throw new Error('[DevTools] is disabled');
  }

  if (dbWorker) return dbWorker;
  if (isInitializing) {
    await new Promise(resolve => setTimeout(resolve, 100));
    return getWorker();
  }

  try {
    isInitializing = true;
    console.info('[DevTools] Initializing database worker...');

    dbWorker = await PGliteWorker.create(
      new Worker(new URL('./pglite.worker.ts', import.meta.url), { type: 'module' }),
      { dataDir: `idb://${DB_NAME}` },
    );

    // Verify connection
    return dbWorker;
  } catch (error) {
    console.error('[DevTools] Worker initialization failed:', error);
    dbWorker = null;
    isInitializing = false;
    throw error;
  }
}

export function resetWorker() {
  if (!devtoolsEnabled) return;

  dbWorker = null;
  isInitializing = false;
}
