const TicketIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='16' viewBox='0 0 14 16' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.275 14.0197L1.347 14.9637C1.27321 15.0004 1.18572 14.9968 1.11528 14.9539C1.04484 14.9111 1.00131 14.8351 1 14.7527V1.41867C1.00165 1.33642 1.04531 1.26074 1.11569 1.21815C1.18607 1.17555 1.27337 1.17198 1.347 1.20867L3.275 2.15267C3.35208 2.19018 3.44319 2.18448 3.515 2.13767L5.067 1.12467C5.14787 1.07209 5.25213 1.07209 5.333 1.12467L6.867 2.12467C6.94787 2.17725 7.05213 2.17725 7.133 2.12467L8.667 1.12467C8.74787 1.07209 8.85213 1.07209 8.933 1.12467L10.484 2.13767C10.5558 2.18448 10.6469 2.19018 10.724 2.15267L12.653 1.20867C12.7266 1.17198 12.8139 1.17555 12.8843 1.21815C12.9547 1.26074 12.9984 1.33642 13 1.41867V14.7527C12.9984 14.8349 12.9547 14.9106 12.8843 14.9532C12.8139 14.9958 12.7266 14.9994 12.653 14.9627L10.725 14.0187C10.6479 13.9812 10.5568 13.9869 10.485 14.0337L8.933 15.0467C8.85213 15.0992 8.74787 15.0992 8.667 15.0467L7.133 14.0467C7.05213 13.9941 6.94787 13.9941 6.867 14.0467L5.333 15.0467C5.25213 15.0992 5.14787 15.0992 5.067 15.0467L3.516 14.0337C3.44376 13.9868 3.35218 13.9815 3.275 14.0197Z'
        stroke='#3E3E3E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4 6.33572C3.58579 6.33572 3.25 6.67151 3.25 7.08572C3.25 7.49994 3.58579 7.83572 4 7.83572V6.33572ZM10 7.83572C10.4142 7.83572 10.75 7.49994 10.75 7.08572C10.75 6.67151 10.4142 6.33572 10 6.33572V7.83572ZM5 8.33572C4.58579 8.33572 4.25 8.67151 4.25 9.08572C4.25 9.49994 4.58579 9.83572 5 9.83572V8.33572ZM9 9.83572C9.41421 9.83572 9.75 9.49994 9.75 9.08572C9.75 8.67151 9.41421 8.33572 9 8.33572V9.83572ZM4 7.83572H10V6.33572H4V7.83572ZM5 9.83572H9V8.33572H5V9.83572Z'
        fill='#3E3E3E'
      />
    </svg>
  );
};

export default TicketIcon;
