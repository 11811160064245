import { PERMISSION_ACTION, PERMISSION_SUBJECT } from '@finance-ops/types';
import { VerticalNavItemsType } from 'apps/webapp/src/@core/layouts/types';
import { defaultPermission } from 'apps/webapp/src/configs/acl';

// ** Type import

const navigation = (): VerticalNavItemsType => {
  return [
    {
      title: 'Get Started',
      path: '/get-started',
      // icon: 'tabler:rocket',
      icon: 'mdi:file-document-outline',
      permission: defaultPermission,
    },
    {
      title: 'Dashboard',
      path: '/',
      icon: 'tabler:layout-dashboard',
      permission: defaultPermission,
    },
    {
      title: 'Customers',
      path: '/customers',
      icon: 'tabler:user',
      permission: defaultPermission,
    },
    {
      title: 'Chats',
      path: '/chat-beta',
      icon: 'mdi:message-group',
      permission: {
        actions: [PERMISSION_ACTION.READ],
        subject: PERMISSION_SUBJECT.CHAT_PAGE,
      },
    },
    {
      title: 'Resolution Center',
      path: '/resolution-center',
      icon: 'material-symbols:support-agent',
      permission: defaultPermission,
    },
    {
      title: 'Payments',
      path: '/payments',
      icon: 'mdi:money',
      permission: defaultPermission,
    },
    {
      title: 'Team Management',
      path: '/team-management-2',
      icon: 'tabler:user-scan',
      permission: {
        actions: [PERMISSION_ACTION.READ],
        subject: PERMISSION_SUBJECT.TEAM_MANAGEMENT,
      },
    },
    {
      title: 'User Management',
      icon: 'tabler:users-group',
      children: [
        {
          title: 'Roles',
          path: '/roles',
          icon: 'tabler:fingerprint',
          permission: {
            actions: [PERMISSION_ACTION.READ],
            subject: PERMISSION_SUBJECT.ROLE_MANAGEMENT,
          },
        },
        {
          title: 'Users',
          path: '/users',
          icon: 'tabler:users',
          permission: {
            actions: [PERMISSION_ACTION.READ],
            subject: PERMISSION_SUBJECT.USER_MANAGEMENT,
          },
        },
      ],
      permission: {
        actions: [PERMISSION_ACTION.READ],
        subject: PERMISSION_SUBJECT.USER_MANAGEMENT,
      },
    },
    {
      title: 'Old Screens',
      icon: 'tabler:archive',
      children: [
        {
          title: 'Reports',
          path: '/report',
          icon: 'carbon:report',
          permission: {
            actions: [PERMISSION_ACTION.READ],
            subject: PERMISSION_SUBJECT.ONLY_SUPER_ADMIN,
          },
        },
      ],
      permission: {
        actions: [PERMISSION_ACTION.READ],
        subject: PERMISSION_SUBJECT.ONLY_SUPER_ADMIN,
      },
    },
    {
      title: 'Error Queue',
      path: '/queue',
      icon: 'tabler:recycle',
      permission: {
        actions: [PERMISSION_ACTION.READ],
        subject: PERMISSION_SUBJECT.QUEUE_PAGE,
      },
    },
    {
      title: 'Strategy',
      path: '/strategy-2',
      icon: 'ic:baseline-switch-access-shortcut',
      permission: {
        actions: [PERMISSION_ACTION.READ],
        subject: PERMISSION_SUBJECT.STRATEGY_PAGE,
      },
    },
    {
      title: 'Workflow',
      path: '/new-workflow',
      icon: 'mdi:sitemap-outline',
      permission: {
        actions: [PERMISSION_ACTION.READ],
        subject: PERMISSION_SUBJECT.WORKFLOW_PAGE,
      },
    },
    {
      title: 'Settings',
      path: '/settings',
      icon: 'carbon:settings',
      permission: {
        actions: [PERMISSION_ACTION.READ],
        subject: PERMISSION_SUBJECT.SETTINGS_PAGE,
      },
    },
    {
      title: 'Settings (Old)',
      path: '/settings-old',
      icon: 'carbon:settings',
      permission: {
        actions: [PERMISSION_ACTION.READ],
        subject: PERMISSION_SUBJECT.ONLY_SUPER_ADMIN,
      },
    },
    {
      title: 'Invoices',
      path: '/all-invoices',
      icon: 'mdi:strategy',
      permission: {
        actions: [PERMISSION_ACTION.READ],
        subject: PERMISSION_SUBJECT.INVOICES_PAGE,
      },
    },
    {
      title: 'Clients',
      path: '/clients',
      icon: 'tabler:users-group',
      permission: {
        actions: [PERMISSION_ACTION.READ],
        subject: PERMISSION_SUBJECT.ONLY_SUPER_ADMIN,
      },
    },
  ];
};

export default navigation;
