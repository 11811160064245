import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppliedFilter } from '../../components/entity-page/EntityFilters';

export interface TableSort {
  id: string;
  desc: boolean;
}

interface TablePagination {
  pageNumber: number;
  pageSize: number;
}

interface PageState {
  filters: Record<string, AppliedFilter>;
  sorts: Record<string, 'asc' | 'desc'>;
  pagination: TablePagination;
  clientId?: string;
  assignedUserId?: string;
  search?: string;
}

interface TableState {
  pageState: Record<string, PageState>;
}

const initialState: TableState = {
  pageState: {},
};

export const tableStateSlice = createSlice({
  name: 'tableState',
  initialState,
  reducers: {
    setPageFilters: (state, action: PayloadAction<{ pageId: string; filters: Record<string, AppliedFilter> }>) => {
      const { pageId, filters } = action.payload;
      if (!state.pageState[pageId]) {
        state.pageState[pageId] = {
          filters: {},
          sorts: {},
          pagination: { pageNumber: 1, pageSize: 10 },
        };
      }
      state.pageState[pageId].filters = filters;
    },
    setPageSort: (state, action: PayloadAction<{ pageId: string; sorts: Record<string, 'asc' | 'desc'> }>) => {
      const { pageId, sorts } = action.payload;
      if (!state.pageState[pageId]) {
        state.pageState[pageId] = {
          filters: {},
          sorts: {},
          pagination: { pageNumber: 1, pageSize: 10 },
        };
      }
      state.pageState[pageId].sorts = sorts;
    },
    setPagePagination: (state, action: PayloadAction<{ pageId: string; pagination: TablePagination }>) => {
      const { pageId, pagination } = action.payload;
      if (!state.pageState[pageId]) {
        state.pageState[pageId] = {
          filters: {},
          sorts: {},
          pagination: { pageNumber: 1, pageSize: 10 },
        };
      }
      state.pageState[pageId].pagination = pagination;
    },
    setClientId: (state, action: PayloadAction<{ pageId: string; clientId: string }>) => {
      const { pageId, clientId } = action.payload;
      if (!state.pageState[pageId]) {
        state.pageState[pageId] = {
          filters: {},
          sorts: {},
          pagination: { pageNumber: 1, pageSize: 10 },
          clientId,
        };
      } else {
        state.pageState[pageId].clientId = clientId;
      }
    },
    setAssignedUserId: (state, action: PayloadAction<{ pageId: string; assignedUserId: string }>) => {
      const { pageId, assignedUserId } = action.payload;
      if (!state.pageState[pageId]) {
        state.pageState[pageId] = {
          filters: {},
          sorts: {},
          pagination: { pageNumber: 1, pageSize: 10 },
          clientId: '',
          assignedUserId: assignedUserId,
        };
      }
      state.pageState[pageId].assignedUserId = assignedUserId;
    },
    setSearch: (state, action: PayloadAction<{ pageId: string; search: string }>) => {
      const { pageId, search } = action.payload;
      if (!state.pageState[pageId]) {
        state.pageState[pageId] = {
          filters: {},
          sorts: {},
          pagination: { pageNumber: 1, pageSize: 10 },
          clientId: '',
          search,
        };
      }
      state.pageState[pageId].search = search;
    },
  },
});

export const { setPageFilters, setPageSort, setPagePagination, setClientId, setAssignedUserId, setSearch } =
  tableStateSlice.actions;

export default tableStateSlice.reducer;
