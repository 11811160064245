import {
  CustomerContext,
  CustomerContextCountPerStateType,
  CustomerContextGet,
  CustomerContextGetAllResponse,
  CustomerContextType,
  UpdateTaskDtoType,
  TaskAssignRequestType,
} from '@finance-ops/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { stringify } from 'qs';
import api from '../../httpClient/api';
import {
  setCustomerContextPaginationLoading,
  upsertManyCustomerContexts,
  upsertManyFilteredAndSortedCustomerContexts,
} from '../customercontext';
import { baseQuery } from './baseQuery';

export const customerContextApi = createApi({
  reducerPath: 'customerContextApi',
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: false,
  keepUnusedDataFor: 5 * 60 * 1000,
  tagTypes: ['CustomerContext', 'CustomerContextCountGroupedByTaskState'],
  endpoints: builder => ({
    getCustomerContext: builder.query<CustomerContext[], CustomerContextGet>({
      query: customerContextGet => {
        const queryString = stringify(customerContextGet);
        return {
          url: `${api.customerContext}?${queryString}`,
          method: 'GET',
        };
      },
      async onQueryStarted(customerContextGet, { dispatch, queryFulfilled }) {
        try {
          dispatch(setCustomerContextPaginationLoading(true));
          const { data } = await queryFulfilled;
          if (customerContextGet.requestHash?.length) {
            dispatch(upsertManyFilteredAndSortedCustomerContexts(data));
          } else {
            dispatch(upsertManyCustomerContexts(data));
          }
        } catch (error) {
          console.error(error);
        } finally {
          dispatch(setCustomerContextPaginationLoading(false));
        }
      },
      providesTags: ['CustomerContext'],
    }),

    getCustomerContextCountGroupedByTaskState: builder.query<CustomerContextCountPerStateType, CustomerContextGet>({
      query: customerContextGetCount => {
        const queryString = stringify(customerContextGetCount);
        return {
          url: `${api.customerContext}/countGroupedByTaskState?${queryString}`,
          method: 'GET',
        };
      },
      providesTags: ['CustomerContextCountGroupedByTaskState'],
      extraOptions: {
        throttle: 3000,
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return JSON.stringify(queryArgs);
      },
    }),

    getCustomerContextByTaskIds: builder.query<CustomerContextGetAllResponse, string[]>({
      query: taskIds => {
        const queryString = stringify({ taskIds });
        return {
          url: `${api.customerContext}/taskIds?${queryString}`,
          method: 'GET',
        };
      },
    }),

    updateTask: builder.mutation<CustomerContextType, { id: string; payload: UpdateTaskDtoType }>({
      query: ({ id, payload }) => ({
        url: `/customerContext/tasks/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'CustomerContext', id }],
    }),

    updateTaskUnassign: builder.mutation<CustomerContextType, { id: string }>({
      query: ({ id }) => ({
        url: `/customerContext/tasks/${id}/unassign`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'CustomerContext', id }],
    }),

    assignUser: builder.mutation<CustomerContext[], TaskAssignRequestType>({
      query: payload => ({
        url: `${api.customerContext}/assignUser`,
        method: 'PATCH',
        body: payload,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(upsertManyCustomerContexts(data));
      },
      invalidatesTags: ['CustomerContext'],
    }),
  }),
});

export const {
  useGetCustomerContextByTaskIdsQuery,
  useGetCustomerContextCountGroupedByTaskStateQuery,
  useGetCustomerContextQuery,
  useUpdateTaskMutation,
  useUpdateTaskUnassignMutation,
  useAssignUserMutation,
} = customerContextApi;
