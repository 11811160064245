export enum ACTION_TYPES {
  ACTION_CENTER = 'ACTION_CENTER',
  P2P = 'P2P',
  FOLLOW_UP = 'FOLLOW_UP',
  INVOICE_STATUS = 'INVOICE_STATUS',
  DISPUTE = 'Dispute',
  MANUAL_INTERVENTION = 'Manual Intervention',
  ESCALATION = 'Escalation',
  CASE_TRANSFER = 'Case Transfer',
}

export enum SEARCH_PARAMS {
  TITLE = 'title',
  DESC = 'desc',
  NAME = 'name',
  CONTACT = 'contact',
  SOURCE_ID = 'sourceId',
  EMAIL = 'email',
  ID = 'id',
  CONTACT_NUMBER = 'contactNumber',
  INVOICE_ID = 'invoiceId',
  PATIENT_ID = 'patientId',
  LOCATION = 'location',
}

export type ActionCenterActionType = ACTION_TYPES.DISPUTE | ACTION_TYPES.ESCALATION | ACTION_TYPES.MANUAL_INTERVENTION;

export const ACTION_CENTER_ACTION_TYPES = new Set([
  ACTION_TYPES.DISPUTE,
  ACTION_TYPES.ESCALATION,
  ACTION_TYPES.MANUAL_INTERVENTION,
]);

export enum P2P_ACTION_TYPES {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
  BROKEN = 'BROKEN',
  HONOURED = 'HONOURED',
  PARTIALLY_KEPT = 'PARTIALLY KEPT',
}

export enum FOLLOW_UP_ACTION_TYPE {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum PRIORITY_TYPES {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum ACTION_CENTER_STATUS_TYPES {
  DRAFT = 'Draft',
  OPEN = 'Open',
  IN_PROGRESS = 'In Progress',
  RESOLVED = 'Resolved',
  CLOSED = 'Closed',
}

export enum DISPLAY_MODES {
  VIEW = 'view',
  EDIT = 'edit',
  ADD = 'add',
}
